export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const formatPhoneNumber = (value: string) => {
  if (!value) return '';

  // Форматируем номер телефона (например, 8 (123) 456-7890)
  const firstPart = value.slice(0, 1);
  const part1 = value.slice(1, 4);
  const part2 = value.slice(4, 7);
  const part3 = value.slice(7, 9);
  const part4 = value.slice(9, 11);

  let formattedNumber = firstPart;
  if (part1) formattedNumber += ` (${part1}`;
  if (part2) formattedNumber += `) ${part2}`;
  if (part3) formattedNumber += `-${part3}`;
  if (part4) formattedNumber += `-${part4}`;

  return formattedNumber;
};
