import { gql, useMutation } from 'urql';
export const CREATE_SEGMENT = gql`
  mutation CreateTicketSegment($title: String!, $eventId: Int!) {
    createTicketSegment(input: { title: $title, description: "", eventId: $eventId }) {
      id
      title
      description
      eventId
    }
  }
`;

export const useAddSegment = () => {
  const [{ data, error }, executeMutation] = useMutation(CREATE_SEGMENT);

  const addSegment = (variables: { title: string; eventId: number }) => {
    return executeMutation(variables);
  };
  return { data, error, addSegment };
};
