const SearchIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="search icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2.5C4.51472 2.5 2.5 4.51472 2.5 7C2.5 9.48528 4.51472 11.5 7 11.5C8.24278 11.5 9.36709 10.9969 10.182 10.182C10.9969 9.36709 11.5 8.24278 11.5 7C11.5 4.51472 9.48528 2.5 7 2.5ZM1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C10.0376 1.5 12.5 3.96243 12.5 7C12.5 8.33855 12.0213 9.56604 11.2266 10.5195L14.3536 13.6464C14.5488 13.8417 14.5488 14.1583 14.3536 14.3536C14.1583 14.5488 13.8417 14.5488 13.6464 14.3536L10.5195 11.2266C9.56604 12.0213 8.33855 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7Z"
        fill="#1D252D"
      />
    </svg>
  );
};

export default SearchIcon;
