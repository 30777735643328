import { useState } from 'react';

const useUploadFileDnd = () => {
  const [dragActive, setDragActive] = useState(false);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
  };

  return {
    dragActive,
    setDragActive,
    handleDragOver,
    handleDragLeave,
  };
};

export default useUploadFileDnd;
