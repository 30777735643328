import { ButtonProps } from '@sminex/sminex-uikit/dist/src/ui-components/button/model/types';
import { FC } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import PlusIcon from '@shared/ui/plus-icon';

export const AddButton: FC<ButtonProps> = ({ children, onClick, settings }, props) => {
  return (
    <button onClick={onClick} {...stylex.props(styles.button)} settings={settings} {...props}>
      <div {...stylex.props(styles.icon)}>
        <PlusIcon />
      </div>
      <div {...stylex.props(styles.children)}>{children}</div>
    </button>
  );
};
