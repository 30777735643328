import { useMutation } from 'urql';
import { gql } from 'urql';

export const SMS_MUTATION = gql`
  mutation ($tickets: [Int!]!, $message: String!) {
    sendSms(info: { ticketIds: $tickets, message: $message })
  }
`;

export interface ISMSVariables {
  message: string;
  tickets: number[];
}

export interface ISMSResponse {
  sendSms: string;
}

export const useFetchSMS = () => {
  return useMutation<ISMSResponse, ISMSVariables>(SMS_MUTATION);
};
