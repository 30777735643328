import { gql, useQuery } from 'urql';

const QrCodeQuery = gql`
  query GetTicketQrcode($userId: Int!, $qrCodeSize: Int!, $eventId: Int!) {
    getAuthQrcodeByUserId(input: { userId: $userId, eventId: $eventId, qrCodeSize: $qrCodeSize })
  }
`;

const useFetchQrCode = (userId: number, eventId: number, qrCodeSize: number) => {
  const [result] = useQuery({
    query: QrCodeQuery,
    variables: { userId, eventId, qrCodeSize },
  });
  const { data, fetching, error } = result;
  return {
    data,
    fetching,
    error,
  };
};
export default useFetchQrCode;
