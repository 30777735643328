import { Button } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { FC } from 'react';
import { createPortal } from 'react-dom';
import { styles } from './styles.ts';

export interface IConfirmProps {
  isOpen: boolean;
  header: string;
  message: string;
  okBtnLabel: string;
  cancelBtnLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
}
export const ConfirmDialog: FC<IConfirmProps> = ({
  isOpen,
  header,
  message,
  okBtnLabel,
  cancelBtnLabel,
  onConfirm,
  onCancel,
}) => {
  return createPortal(
    <div {...stylex.props([styles.overlay])}>
      <div {...stylex.props(styles.modal, isOpen && styles.open)}>
        <div {...stylex.props(styles.header)}>{header}</div>
        <div {...stylex.props(styles.message)}>{message}</div>
        <div {...stylex.props(styles.buttons)}>
          <Button variant="secondary" size="small" settings={{ padding: '8px 24px' }} onClick={onCancel}>
            {cancelBtnLabel}
          </Button>
          <Button size="small" settings={{ padding: '8px 24px' }} onClick={onConfirm}>
            {okBtnLabel}
          </Button>
        </div>
      </div>
    </div>,
    document.getElementById('confirm') as Element
  );
};
