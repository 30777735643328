import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  button: {
    cursor: 'pointer',
    fontFamily: fonts.din2014Regular,
    color: colors.primaryText,
    fontSize: '18px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    userSelect: 'none',
  },
});
