import { gql, useQuery } from 'urql';

import { IOrganizer } from '@features/organizer/model/types';

export const GET_ORGANIZERS_QUERY = gql`
  query ($id: Int!) {
    getGroupById(id: $id) {
      users {
        id
        uuid
        email
        login
        position
        phone
        firstName
        lastName
        avatar
        confirmed
        departamentUuid
        createdAt
        updatedAt
      }
    }
  }
`;

export interface IGetUserResponse {
  getGroupById: { users: IOrganizer[] };
}

export const useGetOrganizers = (id: number | undefined) => {
  const [result, reexecuteQuery] = useQuery<IGetUserResponse>({
    query: GET_ORGANIZERS_QUERY,
    variables: { id },
    pause: !id,
  });
  return {
    data: result.data,
    fetching: result.fetching,
    error: result.error,
    refetch: reexecuteQuery,
  };
};
