import { colors } from '@shared/tokens/colors.stylex';
import { FC } from 'react';

export const EmptyImage: FC = () => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 302 212" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 7.99999C0 3.58172 3.58172 0 8 0H294C298.418 0 302 3.58172 302 8V204C302 208.418 298.418 212 294 212H8.00001C3.58173 212 0 208.418 0 204V7.99999Z"
        fill={colors.ghostBackground}
      />
      <path
        d="M0.5 7.99999C0.5 3.85786 3.85786 0.5 8 0.5H294C298.142 0.5 301.5 3.85786 301.5 8V204C301.5 208.142 298.142 211.5 294 211.5H8.00001C3.85787 211.5 0.5 208.142 0.5 204V7.99999Z"
        stroke="#1D252D"
        strokeOpacity="0.05"
      />
      <path
        d="M192.363 119.279L199.777 110.449L192.635 100.304H196.512C198.416 100.304 198.144 101.079 201.443 105.764L202.566 107.381L207.055 100.54C207.191 100.337 207.429 100.27 207.633 100.27H209.946L203.212 108.291L211 119.279H207.361C204.946 119.313 205.388 118.335 202.192 113.853L200.423 111.359L195.288 118.976C195.152 119.178 194.948 119.245 194.675 119.245H192.363V119.279Z"
        fill="#BBBEC0"
      />
      <path
        d="M184.575 119.649C181.956 119.649 179.235 118.908 177.535 116.818C175.936 114.863 175.358 112.437 175.29 109.976C175.256 106.909 176.038 104.449 177.671 102.595C179.303 100.742 181.548 99.8315 184.405 99.8315C185.765 99.8315 186.989 100.034 188.044 100.438C189.098 100.843 189.914 101.382 190.56 102.09C191.207 102.797 191.683 103.606 191.989 104.516C192.295 105.426 192.465 106.437 192.499 107.516C192.499 108.089 192.465 108.628 192.363 109.1C192.363 109.167 192.295 109.201 192.261 109.201H180.29C180.358 112.268 180.834 114.459 181.718 115.841C182.602 117.223 184.065 117.897 186.139 117.897C188.282 117.897 189.948 117.29 191.581 116.043C191.853 116.279 192.159 116.919 191.581 117.425C190.084 118.773 187.159 119.649 184.575 119.649ZM180.256 108.291L188.01 107.718C188.044 105.157 187.942 100.944 184.507 100.944C180.902 100.944 180.358 105.561 180.256 108.291Z"
        fill="#BBBEC0"
      />
      <path
        d="M172.297 117.088V105.966C172.297 102.158 170.835 99.8657 167.06 99.8657C164.407 99.8657 161.89 101.113 160.088 102.966C160.088 101.483 159.85 100.27 158.387 100.27H155.326L155.496 102.427V117.055L155.326 119.245H160.292L160.122 117.055V103.978C161.55 102.899 162.877 102.36 164.781 102.36C167.196 102.36 167.672 104.584 167.672 107.045V116.684C167.672 118.302 168.046 119.245 169.406 119.245H172.467L172.297 117.088Z"
        fill="#BBBEC0"
      />
      <path
        d="M150.531 94.7768C150.531 96.1249 149.409 97.2034 148.048 97.2034C146.688 97.2034 145.566 96.1249 145.566 94.7768C145.566 93.4286 146.688 92.3501 148.048 92.3501C149.409 92.3501 150.531 93.4286 150.531 94.7768Z"
        fill="#BBBEC0"
      />
      <path
        d="M145.736 100.27L145.906 102.427V117.054L145.736 119.245H150.701L150.531 117.054V102.798C150.531 101.349 150.259 100.236 148.797 100.236H145.736V100.27Z"
        fill="#BBBEC0"
      />
      <path
        d="M141.043 117.088V105.966C141.043 102.124 139.58 99.8657 135.805 99.8657C132.642 99.8657 130.5 101.45 128.799 103.236C128.187 101.113 126.691 99.8657 123.834 99.8657C121.147 99.8657 118.97 101.113 117.202 103V102.966C117.202 101.483 116.964 100.27 115.502 100.27H112.441L112.611 102.427V117.055L112.441 119.245H117.406L117.236 117.055V103.978C118.63 102.832 120.297 102.36 121.623 102.36C124.242 102.36 124.514 105.022 124.514 107.045V117.055L124.344 119.245H129.309L129.139 117.055V103.978C130.33 102.966 131.996 102.36 133.561 102.36C136.281 102.36 136.451 105.292 136.451 107.045V116.684C136.451 118.504 136.961 119.245 138.186 119.245H141.247L141.043 117.088Z"
        fill="#BBBEC0"
      />
      <path
        d="M99.8911 119.65C97.6124 119.65 94.2455 119.01 92.2049 117.864C92.579 117.021 93.2932 116.381 94.3135 116.92C96.15 117.864 97.9865 118.302 100.027 118.302C102.952 118.302 105.095 116.684 104.958 113.752C104.652 107.618 91.9329 106.708 91.9669 99.3268C92.0009 93.9005 96.9322 92.3501 101.66 92.3501C103.938 92.3501 106.183 92.7883 108.393 93.6645C108.019 94.5408 107.373 95.2823 106.251 94.7431C104.686 94.0016 103.122 93.6645 101.455 93.6645C98.5647 93.6645 96.9322 95.2149 96.9322 97.5068C96.8982 103.371 109.822 104.247 110.06 111.865C110.196 117.426 105.503 119.65 99.8911 119.65Z"
        fill="#BBBEC0"
      />
    </svg>
  );
};
