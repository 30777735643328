import { Input } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import React from 'react';
import SearchIcon from '@shared/ui/icons/search-icon';
import CrossIcon from '@shared/ui/icons/cross-icon';

interface InputSearchProps {
  changeValue?: (value: string) => void;
  searchHandler?: () => void;
  clearHandler?: () => void;
  searchValue?: string;
}

const InputSearch: React.FC<InputSearchProps> = ({ changeValue, searchHandler, clearHandler, searchValue }) => {
  const getWidth = () => {
    const maxWidth = document.documentElement.clientWidth;
    const validMaxWidth = maxWidth > 1439 ? 1160 : 834;

    return validMaxWidth;
  };

  return (
    <div {...stylex.props(styles.base)} data-testid="input-search">
      <Input
        changeFunction={changeValue}
        value={searchValue}
        settings={{ width: `${getWidth()}px` }}
        size="small"
        placeholder="Поиск"
      />
      <i {...stylex.props(styles.searchIcon)} onClick={searchHandler} data-testid="search-icon">
        <SearchIcon />
      </i>
      <i onClick={clearHandler} {...stylex.props(styles.crossIcon)} data-testid="cross-icon">
        <CrossIcon />
      </i>
    </div>
  );
};

export default InputSearch;
