import React, { ReactNode, useState } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import ModalWrapper from '@shared/modal-wrapper/index.tsx';
import EmployeeSwitcher from '@entities/employee-switcher/index.tsx';
import CompaniesStructure from './companies-structure.tsx';
import { FreelanceEmployee } from './freelance-employee.tsx';
import WithExcel from './with-excel.tsx';
import { IFreelanceEmployee } from '@shared/types/types.ts';
import { useCreateTickets } from '@pages/event/api/useCreateTickets.ts';
import { cleanPhoneNumber } from '@shared/libs/clean-number.ts';
import { useCreateUser } from '@features/api/use-create-user.ts';
import { useFetchDeleteTicket } from '@pages/event/api/useFetchDeleteTickets.ts';
import { useToast } from '@shared/hooks/useToast.ts';
import { IInvitedParticipants } from '@entities/segments-tab/index.tsx';
import useFetchEventCards from '@shared/hooks/useFetchEventCards.ts';

export interface ISegmentParticipants {
  id: number;
  title: string;
  eventId?: number;
  freelanceEmployee?: IFreelanceEmployee[];
  companiesStructureList?: {
    [key: number]: number;
  };
  companiesStructureTree?: {
    tags?: Array<{
      id?: number;
      title?: string;
    }>;
  };
}

interface AddParticipantsModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (isOpenModal: boolean) => void;
  segment: { id: number; title: string; eventId: number };
  invitedParticipants: IInvitedParticipants[];
}

export const AddParticipantsModal: React.FC<AddParticipantsModalProps> = ({
  isOpenModal,
  setIsOpenModal,
  segment,
  invitedParticipants,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectedParticipants, setSelectedParticipants] = useState<number[]>([]);
  const [freelanceEmployees, setFreelanceEmployees] = useState<IFreelanceEmployee[]>([]);
  const [participantsDelete, setParticipantsDelete] = useState<IInvitedParticipants[]>([]);
  const { showToast } = useToast();
  const { executeQuery } = useFetchEventCards();

  const createTickets = useCreateTickets();
  const { createUser } = useCreateUser();

  const [, deleteTickets] = useFetchDeleteTicket();

  // TODO: реализовать импорт Excel
  const onFileChange = (file: File) => {
    console.log(file);
  };

  const handelChangeSegment = async () => {
    const ids = [...selectedParticipants];

    if (freelanceEmployees) {
      for (const employee of freelanceEmployees) {
        const res = await createUser({
          firstName: employee.firstName,
          lastName: employee.lastName,
          phone: cleanPhoneNumber(employee.phone),
          email: employee.email,
        });
        if (res.data) {
          ids.push(res.data.createUser.id);
        }
      }
    }

    if (participantsDelete.length > 0) {
      const idsToDelete = participantsDelete.map((ticket) => ticket.ticketId);

      const response = await deleteTickets({ id: idsToDelete });
      executeQuery({ requestPolicy: 'network-only' });
      if (response.error) {
        showToast('Ошибка при удалении участников', 'error');

        setIsOpenModal(true);
        return;
      }
    }

    await createTickets(ids, segment.eventId, segment.id);

    setIsOpenModal(false);
  };

  const formsForAdd: ReactNode[] = [
    <CompaniesStructure
      invitedParticipants={invitedParticipants}
      selectedParticipants={selectedParticipants}
      setSelectedParticipants={setSelectedParticipants}
      setParticipantsDelete={setParticipantsDelete}
      participantsDelete={participantsDelete}
    />,
    <FreelanceEmployee freelanceEmployees={freelanceEmployees} onChange={setFreelanceEmployees} />,
    <WithExcel onFileChange={onFileChange} />,
  ];

  const checkingBeforeAdding = () => {
    handelChangeSegment();
  };

  return (
    <ModalWrapper
      active={isOpenModal}
      title={`Редактирование участников: ${segment.title}`}
      size="small"
      settingsContainer={{ height: 'auto' }}
      settingsHeader={{ width: '596px' }}
      buttons={[
        {
          children: <p {...stylex.props(styles.textBtn, styles.cancelBtn)}>Отмена</p>,
          variant: 'secondary',
          onClick: () => {
            setSelectedParticipants([]);
            setParticipantsDelete([]);
            setIsOpenModal(false);
          },
          settings: { width: '97px' },
        },
        {
          children: <p {...stylex.props(styles.textBtn, styles.acceptBtn)}>Сохранить</p>,
          variant: 'accent',
          onClick: () => {
            checkingBeforeAdding();
          },
          settings: { width: '97px' },
        },
      ]}
    >
      <EmployeeSwitcher
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        blocks={['Орг. структура', 'Внештатный участник', 'Импортировать из Excel']}
      />
      {formsForAdd[activeIndex]}
    </ModalWrapper>
  );
};
