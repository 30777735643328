import { useMutation } from 'urql';
import { gql } from 'urql';

export const DELETE_SEGEMENT_MUTATION = gql`
  mutation ($id: [Int!]!) {
    deleteTicketSegment(id: $id) {
      success
      rowsAffected
    }
  }
`;

export interface IDeleteSegmentVariables {
  id: number;
}

export interface IDeleteSegmentResponse {
  deleteTicketSegment: {
    success: boolean;
    rowsAffected: number;
  };
}

export const useFetchDeleteSegment = () => {
  return useMutation<IDeleteSegmentResponse, IDeleteSegmentVariables>(DELETE_SEGEMENT_MUTATION);
};
