import { useMutation } from 'urql';
import { gql } from 'urql';

export const SMS_MUTATION = gql`
  mutation ($users: [Int!]!, $message: String!, $event: Int!) {
    sendSmsToModerator(info: { users: $users, message: $message, event: $event })
  }
`;

export interface ISMSVariables {
  message: string;
  users: number[];
  event: number;
}

export interface ISMSResponse {
  sendSmsToModerator: string;
}

export const useSendSMStoAdmin = () => {
  return useMutation<ISMSResponse, ISMSVariables>(SMS_MUTATION);
};
