import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import FreelanceEmployeeForm from '@entities/freelance-employee-form/index.tsx';
import AddSegment from '@features/add-segment/index.tsx';
import { IFreelanceEmployee } from '@shared/types/types.ts';
import { memo, useCallback } from 'react';
import './styles.css';

interface IFreelanceEmployeeProps {
  freelanceEmployees: IFreelanceEmployee[];
  onChange: (freelanceEmployees: IFreelanceEmployee[]) => void;
  сonditionAdding: boolean;
}

export const FreelanceEmployee: React.FC<IFreelanceEmployeeProps> = memo(
  ({ freelanceEmployees = [], onChange, сonditionAdding }) => {
    const addEployeeHandler = useCallback(() => {
      const newEmployee = { id: Date.now(), firstName: '', lastName: '', email: '', phone: '', comment: '' };
      onChange([...freelanceEmployees, newEmployee]);
    }, [freelanceEmployees, onChange]);

    const changeEployeeChangeHandler = useCallback(
      (employee: IFreelanceEmployee, id: number) => {
        onChange(freelanceEmployees.map((item, i) => (i === id ? employee : item)));
      },
      [freelanceEmployees, onChange]
    );

    return (
      <>
        <div className="custom-scroll">
          <div style={{ maxHeight: '400px' }}>
            {freelanceEmployees.map((employee, index) => (
              <FreelanceEmployeeForm
                key={index}
                employee={employee}
                changeEmployee={(employee: IFreelanceEmployee) => changeEployeeChangeHandler(employee, index)}
                onChangeDelete={onChange}
                freelanceEmployees={freelanceEmployees}
              />
            ))}
          </div>
        </div>
        {сonditionAdding && (
          <div {...stylex.props(styles.reminderFreeForm)}>Все обязательные поля должны быть заполнены!</div>
        )}
        <div {...stylex.props(styles.addSegmentBtn)}>
          <AddSegment text="Добавить участника" onClick={addEployeeHandler} width="auto" />
        </div>
      </>
    );
  }
);
