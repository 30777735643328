import stylex from '@stylexjs/stylex';
import { colors } from '@shared/tokens/colors.stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
import { typography } from '@shared/tokens/typography.stylex';

export const styles = stylex.create({
  container: {
    backgroundColor: colors.primaryBackground,
    fontFamily: fonts.din2014Regular,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '64px 80px',
    gap: '36px',
    height: '100vh',
    alignSelf: 'stretch',
  },
  logo: {
    position: 'absolute',
    top: '64px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '60px',
    fontFamily: fonts.din2014Regular,
  },
  title: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeHeading-3'],
    fontWeight: typography.fontWeighDemi,
    fontStyle: 'normal',
    lineHeight: '36px',
    color: colors.primaryText,
  },

  errorMessage: {
    height: '20px',
    color: colors.errorText,
  },
});
