import { SminexIllustration } from '@shared/ui/sminex-illustration';
import stylex from '@stylexjs/stylex';
import { FC, ReactNode } from 'react';
import { AddButton } from '@features/add-button';
import { styles } from './styles';

interface ICardProps {
  children?: ReactNode;
  handleAdd: () => void;
}
export const EmptyCard: FC<ICardProps> = ({ children, handleAdd }) => {
  return (
    <div {...stylex.props(styles.container)}>
      <SminexIllustration />
      <p>Нажмите на кнопку ниже, чтобы добавить организатора</p>
      <AddButton onClick={handleAdd}>Добавить организатора</AddButton>
      {children}
    </div>
  );
};
