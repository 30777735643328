const ACCESS_TOKEN_NAME = 'accessToken';
const REFRESH_TOKEN_NAME = 'refreshToken';
const EMAIL_NAME = 'email';

export const setJWTokens = (email: string, accessToken: string, refreshToken: string) => {
  localStorage.setItem(ACCESS_TOKEN_NAME, accessToken);
  localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
  localStorage.setItem(EMAIL_NAME, email);
};

export const removeJWTTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN_NAME);
  localStorage.removeItem(REFRESH_TOKEN_NAME);
  localStorage.removeItem(EMAIL_NAME);
};

export const getJWTTokens = (): {
  accessToken: string | null;
  refreshToken: string | null;
  email: string | null;
} => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_NAME);
  const email = localStorage.getItem(EMAIL_NAME);
  return {
    accessToken,
    refreshToken,
    email,
  };
};
