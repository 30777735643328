import { IErrorLayot } from '@shared/ui/error-layout';

export const NOT_FOUNDED: IErrorLayot = {
  title: 'Ошибка 404',
  subtitle: 'Перезагрузите страницу или нажмите кнопку ниже',
};
export const BAD_REQUEST: IErrorLayot = {
  title: 'Ошибка 400',
  subtitle: 'Перезагрузите страницу или нажмите кнопку ниже',
};
export const FORBIDDEN: IErrorLayot = {
  title: 'Ошибка 403',
  subtitle: 'Перезагрузите страницу или нажмите кнопку ниже',
};

export const UNKNOWN_ERROR: IErrorLayot = {
  title: 'Неизвестная ошибка',
  subtitle: 'Перезагрузите страницу или нажмите кнопку ниже',
};
