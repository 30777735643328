import { colors } from '../../shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  base: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '48px',
    zIndex: 10,
    background: colors.primaryBackground,
    borderBottom: '1px solid',
    borderColor: colors.ghostBorder,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '80px',
    paddingRight: '80px',
  },
  logo: {
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
  },
});
