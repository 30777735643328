import { useEffect, useState } from 'react';
import SegmentDetailsCard from '@entities/segment-detail-card';
import AddSegment from '@features/add-segment';
import CreateSegmentModal from '@entities/create-segment-modal/index.tsx';
import { CreateEventFormValues, IFreelanceEmployee } from '@shared/types/types.ts';
import { useConfirm } from '@shared/hooks/useConfirm';

export interface CompaniesStructure {
  id: number;
  title: string;
  tags?: Array<{
    id?: number;
    name?: string;
    participants?: IFreelanceEmployee[];
  }>;
}

const initialSegment = {
  id: Date.now(),
  name: 'Название сегмента',
  companiesStructureList: {},
  freelanceEmployee: [],
};

export interface Segment {
  id: number;
  name: string;
  freelanceEmployee: IFreelanceEmployee[];
  companiesStructureList: {
    [key: string]: boolean;
  };

  companiesStructureTree?: {
    tags?: Array<{
      id?: number;
      name?: string;
    }>;
  };
}

interface SecondStepFormProps {
  setEventFormValues: (eventFormValues: CreateEventFormValues) => void;
  eventFormValues: CreateEventFormValues;
}

const SecondStepForm: React.FC<SecondStepFormProps> = ({ setEventFormValues, eventFormValues }) => {
  const { openConfirmDialog } = useConfirm();

  const [currentSegments, setCurrenSegments] = useState<Segment[]>([structuredClone(initialSegment)]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [currentSegment, setCurrenSegment] = useState<Segment>(structuredClone(initialSegment));

  useEffect(() => {
    const newCurrentSegments = currentSegments
      .map((el) => ({
        ...el,
        companiesStructureList: Object.entries(el.companiesStructureList)
          .filter(([, value]) => value === true)
          .map(([key]) => Number(key)),
      }))
      .filter((segment) => segment.companiesStructureList.length > 0 || segment.freelanceEmployee.length > 0);

    setEventFormValues({ ...eventFormValues, segments: newCurrentSegments });
  }, [currentSegments]);

  useEffect(() => {
    const newCurrentSegments = currentSegments
      .map((el) => ({
        ...el,
        companiesStructureList: Object.entries(el.companiesStructureList)
          .filter(([, value]) => value === true)
          .map(([key]) => Number(key)),
      }))
      .filter((segment) => segment.companiesStructureList.length > 0 || segment.freelanceEmployee.length > 0);

    setEventFormValues({ ...eventFormValues, segments: newCurrentSegments });
  }, [currentSegments]);

  const handelAddSegment = () => {
    setCurrenSegments([...currentSegments, { ...structuredClone(initialSegment), id: Date.now() }]);
  };

  const changeNameSegmentHandler = (value: string, id: number) => {
    setCurrenSegments(currentSegments.map((segment) => (segment.id === id ? { ...segment, name: value } : segment)));
  };

  const openHandler = (segment: Segment) => {
    setIsOpenModal(true);
    setCurrenSegment(segment);
  };

  const editHandler = (editSegment: Segment) => {
    setCurrenSegments(currentSegments.map((el) => (el.id === editSegment.id ? editSegment : el)));
  };

  const deleteSegmentHandler = async (segmentId: number) => {
    const deleteSegment = currentSegments.filter((el) => el.id === segmentId);
    if (
      currentSegments.length === 1 &&
      Object.values(currentSegments[0].companiesStructureList).filter((el) => el).length === 0 &&
      Object.values(currentSegments[0].companiesStructureList).filter((el) => el).length === 0 &&
      currentSegments[0].freelanceEmployee.length === 0 &&
      currentSegments[0].name === 'Название сегмента'
    ) {
      return;
    }
    if (await openConfirmDialog(`Удалить сегмент: ${deleteSegment[0].name} ?`, 'Удаление сегмента', 'Удалить')) {
      const filteredSegments = currentSegments.filter((el: Segment) => el.id !== segmentId);
      if (filteredSegments.length > 0) {
        setCurrenSegments(filteredSegments);
      }
      if (filteredSegments.length === 0) {
        setCurrenSegments([structuredClone(initialSegment)]);
      }
    }
  };

  return (
    <>
      <CreateSegmentModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        segment={currentSegment}
        onChange={editHandler}
      />
      {currentSegments.map((segment) => (
        <SegmentDetailsCard
          key={segment.id}
          segment={segment}
          changeFunctionInputSearch={(value) => changeNameSegmentHandler(value, segment.id)}
          setIsOpenModal={() => openHandler(segment)}
          onChangeComStructure={editHandler}
          setCurrenSegments={setCurrenSegments}
          deleteSegmentHandler={deleteSegmentHandler}
        />
      ))}
      <AddSegment onClick={() => handelAddSegment()} />
    </>
  );
};

export default SecondStepForm;
