import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';
import { typography } from '@shared/tokens/typography.stylex';

export const styles = stylex.create({
  button: {
    background: 'none',
    border: 'none',
    margin: '0',
    padding: '0',
    font: 'inherit',
    textDecoration: 'none',
    outline: 'none',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-1'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightCaption-1'],
    color: colors.accentText,
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
  },
});
