import { Button, Input } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { styles } from '../lib/styles';
import { FC, useState, useEffect } from 'react';
import {
  ILoginVariables,
  passwordValidator,
  emailValidator,
  IValidateValue,
  REQURED_MAIL_MESSAGE,
  REQURED_PASSWORD_MESSAGE,
} from '../lib/auth-validator';

interface LoginFormProps {
  onSubmit: ({ email, password }: ILoginVariables) => void;
  errorMessage?: string;
  onChange?: () => void;
}

export const LoginForm: FC<LoginFormProps> = ({ onSubmit, errorMessage, onChange }) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [passwordErrors, setPasswordErrors] = useState<IValidateValue>(false);
  const [emailErrors, setEmailErrors] = useState<IValidateValue>(false);

  useEffect(() => {
    if (email) {
      emailValidator(email).then(setEmailErrors);
    }
  }, [email]);

  useEffect(() => {
    if (password) {
      passwordValidator(password).then(setPasswordErrors);
    }
  }, [password]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email) {
      setEmailErrors(REQURED_MAIL_MESSAGE);
    }
    if (!password) {
      setPasswordErrors(REQURED_PASSWORD_MESSAGE);
    }
    if (email && password && !emailErrors && !passwordErrors) {
      onSubmit({ email, password });
    }
  };

  const handleChangeEmail = (event: React.SetStateAction<string>) => {
    setEmail(event);
    if (onChange) onChange();
  };

  const handleChangePassword = (event: React.SetStateAction<string>) => {
    setPassword(event);
    if (onChange) onChange();
  };

  return (
    <form {...stylex.props(styles.form)} onSubmit={handleSubmit}>
      <div {...stylex.props(styles.title)}>Авторизация</div>
      <Input
        settings={{ width: '320px' }}
        inputMessage={emailErrors || ''}
        status={emailErrors ? 'error' : undefined}
        labelText="Почта *"
        changeFunction={handleChangeEmail}
        value={email}
        placeholder="Введите почту"
      />
      <Input
        settings={{ width: '320px' }}
        type="password"
        inputMessage={passwordErrors || ''}
        status={passwordErrors ? 'error' : undefined}
        labelText="Пароль *"
        changeFunction={handleChangePassword}
        value={password}
        placeholder="Введите пароль"
      />
      <Button variant="accent" settings={{ width: '100px' }}>
        <div style={{ color: '#fff' }}>Войти</div>
      </Button>
      <p {...stylex.props(styles.errorMessage)}>{errorMessage ? 'Ошибка аутентификации' : ''}</p>
    </form>
  );
};
