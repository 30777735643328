import { CardDetails } from '@shared/types/types';
import * as stylex from '@stylexjs/stylex';
import React, { useState } from 'react';
import { formatDate } from '../../shared/libs/format-date';
import SminexIcon from '@shared/ui/icons/sminex-icon';
import { styles } from './styles';

interface EventCardProps {
  cardDetails?: CardDetails;
  isSkeleton?: boolean;
  onClick?: () => void;
}

const defaultPropsValue = {
  id: Math.ceil(Math.random() * 9999),
  name: 'Мастер-класс по переговорным поединкам',
  description: 'Мастер-класс по переговорным поединкам',
  confirmed: '1430',
  image: null,
  active: true,
  dateStart: '2024-09-01T09:00:07',
  dateEnd: '2024-07-01T12:00:07',
  createdAt: '2024-05-17T07:34:10.940832',
  updatedAt: null,
  tickets: {
    activeCount: 1745,
    checkedCount: 1430,
    registration: 1130,
    total: 0,
  },
};

const EventCard: React.FC<EventCardProps> = ({ cardDetails = defaultPropsValue, isSkeleton, onClick }) => {
  const { image, dateStart, name, active, tickets } = cardDetails && cardDetails;

  const [imageError, setImageError] = useState(false);

  const getWidth = (value: number): number => {
    if (value === 0) return 0;
    const oneProcent = value / tickets.total;
    const maxWidth = 138;
    const kf = oneProcent * 100;
    const width = (maxWidth / 100) * kf;

    return Math.round(width);
  };

  const getPercent = (value: number): number => {
    if (value === 0) return 0;
    const oneProcent = value / tickets.total;
    const kf = oneProcent * 100;
    return Math.round(kf);
  };

  return (
    <div {...stylex.props(styles.base, isSkeleton && styles.skeleton)} data-testid="event-card-switcher">
      {isSkeleton ? (
        <div></div>
      ) : (
        <>
          <div onClick={onClick} {...stylex.props(styles.title, isSkeleton && styles.skeleton)}>
            <div {...stylex.props(styles.icon)}>
              {image && !imageError ? (
                <img src={image} alt={name} onError={() => setImageError(true)} />
              ) : (
                <SminexIcon />
              )}
            </div>
            <div {...stylex.props(styles.titleText)}>{name}</div>
          </div>
          {tickets && Object.keys(tickets).length > 0 && (
            <div {...stylex.props(styles.info, isSkeleton && styles.skeleton)}>
              <div {...stylex.props(styles.infoWidget)}>
                <div {...stylex.props(styles.infoText, styles.infoTitle)}>Приглашено / 100%</div>
                <div {...stylex.props(styles.first(getWidth(tickets.total)), styles.infoValue, styles.infoText)}>
                  {tickets.total}
                </div>
              </div>

              <div {...stylex.props(styles.infoWidget, isSkeleton && styles.skeleton)}>
                <div {...stylex.props(styles.infoText, styles.infoTitle)}>
                  Принято / {getPercent(tickets.checkedCount)}%
                </div>
                <div {...stylex.props(styles.infoValue, styles.infoText)}>
                  <div {...stylex.props(styles.second(getWidth(Number(tickets.checkedCount))))}>
                    {tickets.checkedCount}
                  </div>
                </div>
              </div>

              <div {...stylex.props(styles.infoWidget)}>
                <div {...stylex.props(styles.infoText, styles.infoTitle)}>
                  Зарегистрировано / {getPercent(tickets.checkedCount)}%
                </div>
                <div {...stylex.props(styles.infoValue, styles.infoText)}>
                  <div {...stylex.props(styles.third(getWidth(tickets.checkedCount)))}>{tickets.checkedCount}</div>
                </div>
              </div>
            </div>
          )}

          <div {...stylex.props(styles.divider)}></div>
          <div {...stylex.props(styles.footer)}>
            <div {...stylex.props(styles.switcher)}>
              <p {...stylex.props(styles.switchLabel(active))}>{active ? 'Активно' : 'Завершено'}</p>
            </div>
            <div {...stylex.props(styles.date)}>{formatDate(dateStart)}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default EventCard;
