import { FC } from 'react';

interface ILogoProps {
  colorPrimary: string;
  colorSecodary: string;
}
export const SminexLogo: FC<ILogoProps> = ({ colorPrimary, colorSecodary }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="182" height="24" viewBox="0 0 182 24" fill="none">
      <path
        d="M85.5854 23.225L91.9058 15.6972L85.8174 7.049H89.1225C90.7461 7.049 90.5142 7.70983 93.3264 11.7035L94.2832 13.0827L98.1102 7.25012C98.2261 7.07773 98.4291 7.02026 98.603 7.02026H100.575L94.834 13.8584L101.473 23.225H98.3711C96.3126 23.2537 96.6895 22.4205 93.9643 18.5992L92.4566 16.473L88.0788 22.9664C87.9628 23.1388 87.7889 23.1962 87.5569 23.1962H85.5854V23.225Z"
        fill={colorPrimary}
      />
      <path
        d="M78.9461 23.541C76.7137 23.541 74.3943 22.9089 72.9447 21.1275C71.582 19.4611 71.0892 17.3924 71.0312 15.295C71.0022 12.6804 71.669 10.583 73.0606 9.00273C74.4523 7.42249 76.3658 6.64673 78.8011 6.64673C79.9608 6.64673 81.0046 6.81912 81.9033 7.1639C82.8021 7.50868 83.4979 7.96839 84.0488 8.57176C84.5996 9.17512 85.0055 9.86468 85.2664 10.6404C85.5274 11.4162 85.6723 12.2781 85.7013 13.1976C85.7013 13.686 85.6723 14.1457 85.5854 14.548C85.5854 14.6054 85.5274 14.6341 85.4984 14.6341H75.2931C75.351 17.2487 75.7569 19.1163 76.5107 20.2943C77.2646 21.4723 78.5112 22.0469 80.2798 22.0469C82.1063 22.0469 83.5269 21.5298 84.9185 20.4667C85.1505 20.6678 85.4114 21.2137 84.9185 21.6447C83.6429 22.794 81.1495 23.541 78.9461 23.541ZM75.2641 13.8584L81.8743 13.3699C81.9033 11.1863 81.8163 7.59487 78.8881 7.59487C75.8149 7.59487 75.351 11.5311 75.2641 13.8584Z"
        fill={colorPrimary}
      />
      <path
        d="M68.4799 21.3577V11.8762C68.4799 8.62954 67.2332 6.67578 64.0151 6.67578C61.7537 6.67578 59.6082 7.73885 58.0716 9.31909C58.0716 8.0549 57.8687 7.02056 56.622 7.02056H54.0127L54.1577 8.85939V21.329L54.0127 23.1965H58.2456L58.1006 21.329V10.181C59.3183 9.26163 60.449 8.80193 62.0726 8.80193C64.131 8.80193 64.5369 10.6982 64.5369 12.7956V21.0129C64.5369 22.392 64.8558 23.1965 66.0155 23.1965H68.6249L68.4799 21.3577Z"
        fill={colorPrimary}
      />
      <path
        d="M49.9248 2.33724C49.9248 3.48651 48.968 4.40593 47.8083 4.40593C46.6486 4.40593 45.6919 3.48651 45.6919 2.33724C45.6919 1.18797 46.6486 0.268555 47.8083 0.268555C48.968 0.268555 49.9248 1.18797 49.9248 2.33724Z"
        fill={colorPrimary}
      />
      <path
        d="M45.8369 7.01994L45.9819 8.85877V21.3283L45.8369 23.1959H50.0698L49.9248 21.3283V9.17481C49.9248 7.93935 49.6929 6.99121 48.4462 6.99121H45.8369V7.01994Z"
        fill={colorPrimary}
      />
      <path
        d="M41.8362 21.3577V11.8762C41.8362 8.60081 40.5895 6.67578 37.3714 6.67578C34.6751 6.67578 32.8486 8.02617 31.399 9.54895C30.8771 7.73885 29.6014 6.67578 27.1661 6.67578C24.8757 6.67578 23.0202 7.73885 21.5125 9.34783V9.31909C21.5125 8.0549 21.3096 7.02056 20.0629 7.02056H17.4536L17.5986 8.85939V21.329L17.4536 23.1965H21.6865L21.5415 21.329V10.181C22.7302 9.20417 24.1509 8.80193 25.2816 8.80193C27.514 8.80193 27.7459 11.0717 27.7459 12.7956V21.329L27.601 23.1965H31.8338L31.6889 21.329V10.181C32.7036 9.31909 34.1242 8.80193 35.4579 8.80193C37.7773 8.80193 37.9222 11.3016 37.9222 12.7956V21.0129C37.9222 22.5644 38.3571 23.1965 39.4009 23.1965H42.0102L41.8362 21.3577Z"
        fill={colorPrimary}
      />
      <path
        d="M6.75529 23.5412C4.8128 23.5412 1.94255 22.9953 0.203004 22.0185C0.52192 21.3002 1.13076 20.7543 2.00053 21.214C3.56612 22.0185 5.13171 22.392 6.87125 22.392C9.3646 22.392 11.1911 21.0129 11.0751 18.5132C10.8142 13.284 -0.0289344 12.5083 5.80357e-05 6.21602C0.0290504 1.59022 4.23295 0.268555 8.26289 0.268555C10.2054 0.268555 12.1189 0.642074 14.0034 1.3891C13.6845 2.13612 13.1336 2.76822 12.1769 2.30851C10.8432 1.67641 9.50956 1.3891 8.08893 1.3891C5.62458 1.3891 4.23294 2.71076 4.23294 4.66451C4.20395 9.66383 15.2211 10.4109 15.424 16.9042C15.54 21.645 11.539 23.5412 6.75529 23.5412Z"
        fill={colorPrimary}
      />
      <path
        d="M178.547 23.4815C177.864 23.4815 177.268 23.3856 176.759 23.1938C176.25 22.9919 175.837 22.7243 175.521 22.3912C175.216 22.048 174.966 21.6139 174.773 21.0889C174.589 20.564 174.462 20.0188 174.391 19.4535C174.319 18.8781 174.284 18.2118 174.284 17.4547V8.11165H171.411V7.26366H174.284V2.44829L176.622 2.32715V7.26366H181.435L181.297 8.11165H176.622V18.3935C176.622 18.7872 176.622 19.0951 176.622 19.3172C176.632 19.5393 176.652 19.827 176.683 20.1804C176.713 20.5236 176.754 20.7962 176.805 20.9981C176.866 21.2 176.953 21.4271 177.065 21.6795C177.177 21.9218 177.314 22.1085 177.477 22.2398C177.64 22.371 177.844 22.482 178.089 22.5729C178.343 22.6638 178.628 22.7092 178.944 22.7092C179.851 22.7092 180.737 22.5174 181.603 22.1338L181.909 22.7849C180.768 23.2493 179.647 23.4815 178.547 23.4815Z"
        fill={colorSecodary}
      />
      <path
        d="M155.86 23.2396L155.951 21.3921V8.7783L155.844 7.30946L158.182 7.18832L157.922 9.88371H157.999C158.64 9.12658 159.517 8.45525 160.627 7.86973C161.737 7.27412 162.878 6.97632 164.05 6.97632C165.425 6.97632 166.484 7.39022 167.228 8.21802C167.982 9.04582 168.359 10.2471 168.359 11.822V21.3921L168.466 23.2396H166.021L166.128 21.3921V12.6245C166.128 12.251 166.118 11.933 166.097 11.6705C166.077 11.398 166.031 11.085 165.96 10.7317C165.899 10.3784 165.792 10.0806 165.639 9.83828C165.486 9.596 165.287 9.36381 165.043 9.14172C164.798 8.90953 164.477 8.73792 164.08 8.62687C163.683 8.51582 163.225 8.4603 162.705 8.4603C161.758 8.4603 160.943 8.64201 160.26 9.00544C159.578 9.36886 158.89 9.89381 158.197 10.5803V21.3921L158.289 23.2396H155.86Z"
        fill={colorSecodary}
      />
      <path
        d="M145.74 23.5273C143.397 23.5273 141.62 22.8055 140.408 21.3619C139.195 19.9082 138.589 17.8942 138.589 15.3199C138.589 12.6548 139.216 10.6005 140.469 9.15686C141.732 7.70317 143.484 6.97632 145.725 6.97632C147.691 6.97632 149.148 7.52145 150.095 8.61173C151.053 9.702 151.532 11.2112 151.532 13.1394C151.532 13.634 151.506 14.0934 151.455 14.5174L151.394 14.5779L141.065 14.5931C141.075 17.4904 141.477 19.5548 142.272 20.7864C143.066 22.0079 144.33 22.6187 146.061 22.6187C147.528 22.6187 149 22.1644 150.477 21.2559L150.905 22.013C149.326 23.0225 147.605 23.5273 145.74 23.5273ZM141.065 13.9117L149.178 13.7754V12.7002C149.178 11.0043 148.883 9.75752 148.292 8.96001C147.712 8.1524 146.815 7.74859 145.603 7.74859C144.218 7.74859 143.153 8.26849 142.409 9.30829C141.676 10.3481 141.228 11.8825 141.065 13.9117Z"
        fill={colorSecodary}
      />
      <path
        d="M129.294 23.2392L123.808 8.73251L123.121 7.26367H125.627L126.039 8.7628L129.599 19.0144L130.363 21.3918H130.562L135.406 8.73251L135.727 7.26367H137.255L136.521 8.73251L130.852 23.2392H129.294Z"
        fill="#b9a476"
      />
      <path
        d="M110.69 23.2393L110.797 21.3616V4.26549L110.69 2.75122H121.31L121.218 3.72035L119.186 3.59921H113.089V11.958L120.974 11.852V12.7151H113.089V22.3761H119.217L121.188 22.2853V23.2393H110.69Z"
        fill="#b9a476"
      />
    </svg>
  );
};
