import { anatomy } from '@shared/tokens/anatomy.stylex';
import { colors } from '@shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';
import { FC } from 'react';

interface IQrCodeProps {
  svg: string;
}
const styles = stylex.create({
  container: {
    ':not(#__unused__) > svg': {
      borderRadius: anatomy['size-8'],
      borderColor: `1px solid ${colors.tertiaryBorder}`,
    },
  },
});

export const QrCode: FC<IQrCodeProps> = ({ svg }) => {
  return <div dangerouslySetInnerHTML={{ __html: svg }} {...stylex.props(styles.container)} />;
};
