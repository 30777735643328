import PlusIcon from '../plus-icon';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import React, { ReactNode } from 'react';

interface ButtonWithPlusProps {
  handleClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
  labelText: string | ReactNode;
}

const ButtonWithPlus: React.FC<ButtonWithPlusProps> = ({ handleClick, labelText = 'Добавить' }) => {
  return (
    <button {...stylex.props(styles.button)} onClick={handleClick}>
      <PlusIcon></PlusIcon>
      {labelText}
    </button>
  );
};

export default ButtonWithPlus;
