import { gql, useMutation } from 'urql';
export const CREATE_USER = gql`
  mutation CreateUser($firstName: String!, $lastName: String!, $email: String!, $phone: String!) {
    createUser(input: { firstName: $firstName, lastName: $lastName, password: "1234", email: $email, phone: $phone }) {
      id
    }
  }
`;

export const useCreateUser = () => {
  const [{ data, error }, executeMutation] = useMutation(CREATE_USER);

  const createUser = (variables: { firstName: string; lastName: string; email: string; phone: string }) => {
    return executeMutation(variables);
  };
  return { data, error, createUser };
};
