import { colors } from '@shared/tokens/colors.stylex';

const LogoutIcon = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.16602 1.5C1.61373 1.5 1.16602 1.94772 1.16602 2.5L1.16602 11.5C1.16602 12.0523 1.61373 12.5 2.16602 12.5H6.16602C6.7183 12.5 7.16602 12.0523 7.16602 11.5V9C7.16602 8.72386 7.38987 8.5 7.66602 8.5C7.94216 8.5 8.16602 8.72386 8.16602 9V11.5C8.16602 12.6046 7.27059 13.5 6.16602 13.5H2.16602C1.06145 13.5 0.166016 12.6046 0.166016 11.5L0.166016 2.5C0.166016 1.39543 1.06145 0.5 2.16602 0.5L6.16602 0.5C7.27059 0.5 8.16602 1.39543 8.16602 2.5V5C8.16602 5.27614 7.94216 5.5 7.66602 5.5C7.38987 5.5 7.16602 5.27614 7.16602 5V2.5C7.16602 1.94772 6.7183 1.5 6.16602 1.5L2.16602 1.5ZM9.31246 4.64645C9.50773 4.45119 9.82431 4.45119 10.0196 4.64645L12.0196 6.64645C12.2148 6.84171 12.2148 7.15829 12.0196 7.35356L10.0196 9.35355C9.82431 9.54882 9.50772 9.54882 9.31246 9.35355C9.1172 9.15829 9.1172 8.84171 9.31246 8.64645L10.4589 7.5L3.16602 7.5C2.88987 7.5 2.66602 7.27614 2.66602 7C2.66602 6.72386 2.88987 6.5 3.16602 6.5L10.4589 6.5L9.31246 5.35355C9.1172 5.15829 9.1172 4.84171 9.31246 4.64645Z"
        fill={colors.primaryText}
      />
    </svg>
  );
};

export default LogoutIcon;
