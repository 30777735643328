import { gql, useQuery } from 'urql';
import { IEventParticipant } from './useFetchTickets';
// getEventById(id: $id) {
//   id
//   name
//   description
//   confirmed
//   active
//   image
//   dateStart
//   dateEnd
//   segments {
//     id
//     title
//     description
//     eventId
//   }
// }
export const EVENT_QUERY = gql`
  query ($id: Int!) {
    getEventById(id: $id) {
      id
      name
      description
      confirmed
      active
      image
      dateStart
      dateEnd
      createdAt
      updatedAt
      tickets {
        activeCount
        checkedCount
        registration
        total
        tickets {
          id
          ticketUuid
          checked
          inside
          active
          registration
          createdAt
          updatedAt
          ticketSegmentId
        }
      }
    }
    getSegmentsByEventId(id: $id) {
      id
      title
      description
      eventId
    }
    getGroupsByEventId(id: $id) {
      id
      name
    }
  }
`;

export interface IEventVariables {
  id: number;
}

export interface ISegment {
  id: number;
  title: string;
  eventId?: number;
  tickets?: ITickets[];
}
export interface ITickets {
  id: number;
  active: boolean;
  checked: boolean;
  createdAt: string;
  inside: boolean;
  registration: boolean;
  ticketUuid: string;
  updatedAt: string;
  users: IEventParticipant;
}

export interface IEvent {
  id: number;
  name: string;
  description: string;
  confirmed: string;
  active: boolean;
  image: string;
  dateStart: string;
  dateEnd: string;
  segemets: ISegment[];
}
export interface IAdminGroup {
  id: number;
  name: string;
}

export interface IEventResponse {
  getEventById: IEvent;
  getSegmentsByEventId: Array<ISegment[]>;
  getGroupsByEventId: Array<IAdminGroup[]>;
}

export const useFetchEvent = (id: number) => {
  const [{ error, fetching, data: event }] = useQuery<IEventResponse>({
    query: EVENT_QUERY,
    variables: { id },
    requestPolicy: 'network-only',
  });
  return { error, event, fetching };
};
