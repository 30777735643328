import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import ImageIcon from '@shared/ui/icons/image-icon.tsx';
import React, { useRef } from 'react';
import ButtonWithPlus from '@shared/ui/button-with-plus/index.tsx';
import useUploadFileDnd from '@shared/hooks/useUploadFileDnd.ts';

interface InputFileProps {
  onFileChange: (file: File) => void;
  setPreviewUrl: (preview: string) => void;
}

const InputFile: React.FC<InputFileProps> = ({ onFileChange, setPreviewUrl }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { dragActive, handleDragLeave, handleDragOver, setDragActive } = useUploadFileDnd();

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    fileInputRef?.current?.click();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);

    const file = event.dataTransfer.files?.length && event.dataTransfer.files[0];

    if (file) {
      const { size } = file;
      const maxSize = 1.5 * 1024 * 1024;
      if (size > maxSize) return;
      if (fileInputRef.current) {
        fileInputRef.current.files = event.dataTransfer.files;
      }
      onFileChange(file);
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
      event.dataTransfer.clearData();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onFileChange && event.target.files?.length) {
      const file = event.target.files[0];
      onFileChange(file);
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
    }
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      {...stylex.props(styles.inputFileContainer(dragActive))}
    >
      <div {...stylex.props(styles.content)}>
        <ImageIcon />
        <p {...stylex.props(styles.description)}>Нажмите на кнопку ниже или перетащите изображение</p>
        <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
        <ButtonWithPlus labelText="Добавить" handleClick={handleClick} />
      </div>
    </div>
  );
};

export default InputFile;
