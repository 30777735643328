import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from '../../entities/segment-detail-card/styles';

export const Pencil: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...stylex.props(styles.pencilIcon)}
    >
      <g id="Icons/pencil">
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2254 2.77459C16.8593 2.40847 16.2657 2.40847 15.8996 2.77459L14.9353 3.73889L16.2611 5.06472L17.2254 4.10041C17.5915 3.7343 17.5915 3.1407 17.2254 2.77459ZM15.3772 5.9486L14.0514 4.62278L3.92573 14.7485C3.55859 15.1156 3.28871 15.5684 3.14049 16.066L2.80384 17.1962L3.934 16.8595C4.4316 16.7113 4.88442 16.4414 5.25156 16.0743L15.3772 5.9486ZM15.0157 1.8907C15.87 1.03643 17.255 1.03643 18.1093 1.8907C18.9636 2.74498 18.9636 4.13002 18.1093 4.9843L6.13544 16.9582C5.62145 17.4722 4.9875 17.85 4.29085 18.0575L2.05344 18.724C1.8335 18.7895 1.59535 18.7292 1.43307 18.5669C1.2708 18.4047 1.21051 18.1665 1.27603 17.9466L1.94251 15.7092C2.15003 15.0125 2.52785 14.3786 3.04184 13.8646L15.0157 1.8907Z"
        />
      </g>
    </svg>
  );
};
