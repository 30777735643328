import { FC } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import SminexText from './sminex-text';
import EventText from './event-text';
import { LogoutButton } from './logout-button';
import { Link } from 'react-router-dom';
// import { SminexLogo } from '@shared/ui/sminex-logo';

export const Header: FC = () => {
  return (
    <nav {...stylex.props(styles.base)}>
      <Link to="/" {...stylex.props(styles.logo)}>
        <SminexText />
        {/* <SminexLogo  /> */}
        <EventText />
      </Link>
      <LogoutButton />
    </nav>
  );
};
