import { colors } from '@shared/tokens/colors.stylex';
import { FC } from 'react';

interface IconsProps {
  color?: string;
  size?: string;
}

export const TrashIcon: FC<IconsProps> = ({ color = colors.primaryText, size = '16' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00008 2C7.63498 2 7.27126 2.00584 6.90901 2.01743C6.40593 2.03352 6.00008 2.45596 6.00008 2.98546V3.06055C6.66166 3.02038 7.32852 3 8.00008 3C8.67164 3 9.33851 3.02038 10.0001 3.06055V2.98546C10.0001 2.45596 9.59423 2.03352 9.09115 2.01743C8.7289 2.00584 8.36518 2 8.00008 2ZM11.0001 3.13665V2.98546C11.0001 1.94248 10.1917 1.05212 9.12313 1.01794C8.75022 1.00601 8.37585 1 8.00008 1C7.62432 1 7.24994 1.00601 6.87704 1.01794C5.80844 1.05212 5.00008 1.94248 5.00008 2.98546V3.13665C4.3636 3.19492 3.73239 3.27154 3.10699 3.36599C2.87543 3.40095 2.64467 3.43837 2.41474 3.4782C2.14265 3.52533 1.96029 3.78411 2.00742 4.0562C2.05455 4.32829 2.31333 4.51066 2.58542 4.46352C2.63183 4.45549 2.67826 4.44755 2.72474 4.43971L3.39502 13.1534C3.47517 14.1954 4.34406 15 5.38913 15H10.611C11.6561 15 12.525 14.1954 12.6051 13.1534L13.2754 4.43971C13.3219 4.44755 13.3683 4.45549 13.4147 4.46352C13.6868 4.51066 13.9456 4.32829 13.9927 4.0562C14.0399 3.78411 13.8575 3.52533 13.5854 3.4782C13.3555 3.43837 13.1247 3.40095 12.8932 3.36599C12.2678 3.27154 11.6366 3.19492 11.0001 3.13665ZM12.2841 4.28881C11.6819 4.20693 11.0742 4.14207 10.4616 4.09473C9.6494 4.03197 8.82853 4 8.00008 4C7.17164 4 6.35076 4.03197 5.5386 4.09473C4.92598 4.14207 4.31831 4.20693 3.71608 4.28881L4.39207 13.0767C4.43215 13.5977 4.86659 14 5.38913 14H10.611C11.1336 14 11.568 13.5977 11.6081 13.0767L12.2841 4.28881ZM6.15394 5.50037C6.42988 5.48976 6.66218 5.70485 6.67279 5.98078L6.90356 11.9808C6.91417 12.2567 6.69908 12.489 6.42314 12.4996C6.14721 12.5102 5.91491 12.2952 5.9043 12.0192L5.67353 6.01922C5.66292 5.74328 5.878 5.51098 6.15394 5.50037ZM9.84622 5.50037C10.1222 5.51098 10.3372 5.74328 10.3266 6.01922L10.0959 12.0192C10.0853 12.2952 9.85296 12.5102 9.57702 12.4996C9.30108 12.489 9.08599 12.2567 9.09661 11.9808L9.32737 5.98078C9.33799 5.70485 9.57028 5.48976 9.84622 5.50037Z"
        fill={color}
      />
    </svg>
  );
};
