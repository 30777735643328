import { Switcher } from '@sminex/sminex-uikit';
import stylex from '@stylexjs/stylex';
import { FC, ReactNode, useEffect, useState } from 'react';

import { styles } from './styles';
import { ModalAddOrganizer } from '@features/modal-organizer';
import { OranizerTab } from '@features/organizer-tab';
import { IOrganizer } from '@features/organizer/model/types';
import { SegmensTab } from '@entities/segments-tab';
import { ITab, Tabs } from '@entities/tabs';

import { IEvent, IEventResponse, ISegment } from '@pages/event/api/useFetchEvent';
import { ModalAddSegment } from '@features/add-segment/ui/modal-add-segment';
import { ModalTickets } from '@features/modal-sms';
import { convertToISO8601 } from '@pages/event/lib/date-converter';
import { TABS_LIST } from '../../lib/constants';
import { useConfirm } from '@shared/hooks/useConfirm';
import { useFetchSMS } from '../../api/useFetchSms';
import { useFetchAddSegment } from '@pages/event/api/useFetchAddSegment';
import { useFetchDeleteSegment } from '@pages/event/api/useFetchDeleteSegment';
import { useFetchAddOrganizer } from '@pages/event/api/useFetchAddOrganizer';
import { useFetchDeleteOrganizer } from '@pages/event/api/useFetchDeleteOrganizer';
import { useGetOrganizers } from '@pages/event/api/useGetOrganizers';
import { useSendSMStoAdmin } from '@pages/event/api/useSendSmsToAdmin';
import { useToast } from '@shared/hooks/useToast';
import { useCreateUser } from '@features/api/use-create-user';
interface IEventsProps {
  event: IEventResponse | undefined;
}

export const EventBody: FC<IEventsProps> = ({ event }) => {
  const { openConfirmDialog } = useConfirm();

  const [activeTab, setActiveTab] = useState<ITab>(TABS_LIST[0]);
  const [organizers, setOrganizers] = useState<IOrganizer[]>([]);
  const [showModalOranizer, setShowModalOranizer] = useState(false);
  const [showModalAddSegment, setShowModalAddSegment] = useState(false);

  const [usersSMS, setUsersSMS] = useState<{ users: number[]; tickets: number[]; isAdmin: boolean }>({
    users: [],
    tickets: [],
    isAdmin: false,
  });

  const [showModalTickets, setShowModalTickets] = useState(false);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [segments, setSegments] = useState<ISegment[]>([]);

  const [currentEvent, setCurrentEvent] = useState<IEvent | undefined>(undefined);
  const [{ error: addSegmentError }, addSegment] = useFetchAddSegment();
  const [, deleteSegment] = useFetchDeleteSegment();
  const [{ error: addOrganizerError }, addOrganizer] = useFetchAddOrganizer();
  const [, deleteOrganizer] = useFetchDeleteOrganizer();
  const { data: organizersList } = useGetOrganizers(event?.getGroupsByEventId[0][0].id);

  const { showToast } = useToast();

  const { createUser } = useCreateUser();

  useEffect(() => {
    if (!event) return;
    setCurrentEvent(event.getEventById);
    setSegments(event.getSegmentsByEventId[0]);
  }, [event]);

  useEffect(() => {
    if (!organizersList) return;
    setOrganizers(organizersList.getGroupById.users);
  }, [organizersList]);

  const [{ error: smsError, fetching: smsFetching }, sender] = useFetchSMS();
  const [{ error: smsErrorAdmin, fetching: smsFetchingAdmin }, senderAdmin] = useSendSMStoAdmin();
  //обновление эвента
  const handleUpdateEvent = (key: keyof IEvent, value: IEvent[keyof IEvent]) => {
    // validation
    if (!currentEvent) return;
    if (key === 'dateStart' || key === 'dateEnd') {
      value = convertToISO8601(value as string);
    }
    setCurrentEvent({ ...currentEvent, [key]: value });
  };

  const handleCancelOrganizer = () => {
    setShowModalOranizer(false);
  };

  const handleCancelTickets = () => {
    setShowModalTickets(false);
  };

  // сегменты
  const handleAddSegment = async (title: string) => {
    if (!event) return;
    const res = await addSegment({ title, eventId: event.getEventById.id, description: '' });
    if (addSegmentError || res.data === undefined) {
      showToast('Произошла ошибка при добавлении сегмента', 'error');
      return;
    }
    segments.push(res.data.createTicketSegment);
    setSegments(segments);
    setShowModalAddSegment(false);
  };

  const handleCancelAddSegment = () => {
    setShowModalAddSegment(false);
  };

  // приглдашения СМС
  const handleShowModalTickets = ({
    users,
    tickets = [],
    isAdmin = false,
  }: {
    users: number[];
    tickets?: number[];
    isAdmin?: boolean;
  }) => {
    setUsersSMS({ users, tickets, isAdmin });
    setShowModalTickets(true);
  };

  const sendMessage = async (message: string) => {
    if (!event) return;

    if (usersSMS.isAdmin) {
      const res = await senderAdmin({ message, users: usersSMS.users, event: event.getEventById.id });
      if (smsErrorAdmin || res.error) {
        showToast('Произошла ошибка при отправке СМС', 'error');
        return;
      }
      setShowModalTickets(false);

      return;
    }
    const res = await sender({ message, tickets: usersSMS.tickets });
    if (smsError || res.error) {
      showToast('Произошла ошибка при отправке СМС', 'error');
      return;
    }
    setShowModalTickets(false);
  };

  /// организаторы
  const handleAddOrganizer = async (newOrganizer: Omit<IOrganizer, 'id'>) => {
    if (!event) return;
    const user = await createUser({
      lastName: newOrganizer.lastName,
      firstName: newOrganizer.firstName,
      phone: newOrganizer.phone,
      email: newOrganizer.email,
    });
    if (!user.data) return;
    const newUser = user.data.createUser;
    const adminId = event.getGroupsByEventId[0][0].id;
    await addOrganizer({ userId: newUser.id, groupId: adminId });
    if (addOrganizerError) {
      showToast('Произошла ошибка при добавлении организатора', 'error');
      return;
    }

    setOrganizers([...organizers, newOrganizer]);
    setShowModalOranizer(false);
  };

  const handleDeleteOrganizer = async (organizer: IOrganizer) => {
    if (!event) return;
    const adminId = event.getGroupsByEventId[0][0].id;
    const res = await deleteOrganizer({ userId: organizer.id, groupId: adminId });
    if (res.data !== undefined && res.data.deleteUserFromGroup.success) {
      setOrganizers(organizers.filter((item) => item.id !== organizer.id));
      return;
    }
    showToast('Произошла ошибка при удалении организатора', 'error');
  };
  const handleChange = (organizer: IOrganizer) => {
    setOrganizers(organizers.map((item) => (item.id === organizer.id ? organizer : item)));
  };

  /**
   * Удаление сегмента
   * @param segment
   */
  const handleDeleteSegment = async (segment: ISegment) => {
    if (await openConfirmDialog(`Удалить сегмент ${segment.title} ?`, 'Удаление сегмента', 'Удалить')) {
      const res = await deleteSegment({ id: segment.id });
      if (res.data !== undefined && res.data.deleteTicketSegment.success) {
        setSegments(segments.filter((item) => item.id !== segment.id));
        return;
      }
      showToast('Произошла ошибка при удалении сегмента', 'error');
    }
  };

  const TABS: ReactNode[] = [
    <SegmensTab
      segments={segments}
      onDeleteSegment={handleDeleteSegment}
      onAddSegment={() => setShowModalAddSegment(true)}
      sendTickets={(users, tickets) => handleShowModalTickets({ users, tickets })}
    />,
    <OranizerTab
      organizers={organizers}
      handleAdd={() => setShowModalOranizer(true)}
      handleChange={(e) => handleChange(e)}
      handleDelete={handleDeleteOrganizer}
      sendTickets={(users) => handleShowModalTickets({ users, isAdmin: true })}
      setSortDirection={setSortDirection}
      setOrganizers={setOrganizers}
      sortDirection={sortDirection}
    />,
  ];

  return (
    <>
      <ModalAddOrganizer
        active={showModalOranizer}
        closeHandle={handleCancelOrganizer}
        addOrganizer={handleAddOrganizer}
      />
      <ModalAddSegment
        active={showModalAddSegment}
        closeHandle={handleCancelAddSegment}
        addSegment={handleAddSegment}
      />

      <ModalTickets
        isLoading={smsFetching || smsFetchingAdmin}
        active={showModalTickets}
        closeHandle={handleCancelTickets}
        sendTickets={sendMessage}
      />

      <div {...stylex.props(styles.base)}>
        <h1 {...stylex.props(styles.header)}>{currentEvent?.name}</h1>
        <div {...stylex.props(styles.row)}>
          <div {...stylex.props(styles.row)}>
            <Switcher
              changeFunction={() => handleUpdateEvent('active', !currentEvent?.active)}
              size="small"
              isActive={currentEvent?.active}
              variant="default"
            />
            <p {...stylex.props(styles.switcherLabel)}>Активность</p>
          </div>
          <p {...stylex.props(styles.dateLabel)}>Дата проведения: {currentEvent?.dateStart.split('T')[0]}</p>
        </div>
        <Tabs activeTab={activeTab} size="medium" tabs={TABS_LIST} onChange={setActiveTab} />
        <div {...stylex.props(styles.tabs)}>{TABS[activeTab.id]}</div>
      </div>
    </>
  );
};
