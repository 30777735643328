import { fonts } from '@shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';
import { typography } from '@shared/tokens/typography.stylex';
import { colors } from '@shared/tokens/colors.stylex';

export const styles = stylex.create({
  base: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '112px',
    background: colors.primaryBackground,
    minHeight: '100vh',
    overflowY: 'auto',
  },
  stepper: {
    width: '390px',
    paddingBottom: '36px',
  },
  btnText: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeBody-3'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightBody-3'],
    textAlign: 'left',
  },
  prevBtn: {
    color: colors.primaryText,
  },
  nextBtn: {
    color: colors.primaryInverseText,
  },
});
