import { useAuth } from '@features/auth/hooks/useAuth';
import { Outlet, Navigate } from 'react-router-dom';

export function PrivatePage() {
  const { data } = useAuth();
  if (!data.accessToken) {
    return <Navigate to={'/auth'} />;
  }
  return <Outlet />;
}
