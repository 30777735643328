import { FC } from 'react';
import * as stylex from '@stylexjs/stylex';
import { BasicButton } from '@shared/ui/basic-button';
import { SminexIllustration } from '@shared/ui/sminex-illustration';

import { colors } from '@shared/tokens/colors.stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
import { anatomy } from '@shared/tokens/anatomy.stylex';

import { useNavigate } from 'react-router-dom';

export interface IErrorLayot {
  title: string;
  subtitle: string;
}
interface IErrorLayotProps {
  error: IErrorLayot;
}

const styles = stylex.create({
  container: {
    backgroundColor: colors.primaryBackground,
    fontFamily: fonts.din2014Regular,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: anatomy['size-24'],
    height: '100vh',
  },
  title: {
    fontSize: anatomy['size-44'],
    color: colors.primaryInverseBackground,
  },
  subTitle: { fontSize: '18px', color: colors.primaryInverseBackground, textAlign: 'center' },
});

export const ErrorLayot: FC<IErrorLayotProps> = ({ error }) => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate('/');
  };
  return (
    <div {...stylex.props(styles.container)}>
      <SminexIllustration />
      <p {...stylex.props(styles.title)}>{error.title}</p>
      <p {...stylex.props(styles.subTitle)}>{error.subtitle}</p>
      <BasicButton onClick={handleBackClick}>На главную</BasicButton>
    </div>
  );
};
