import { IUser } from '@shared/hooks/useFetchUsers';
import { gql, useQuery } from 'urql';

export const GET_TICKETS_BY_SEGMENT_QUERY = gql`
  query ($id: Int!) {
    getTicketSegmentById(id: $id) {
      id
      title
      description
      eventId
      tickets {
        id
        ticketUuid
        checked
        inside
        active
        registration
        createdAt
        updatedAt
        ticketSegmentId
        user {
          id
          uuid
          email
          login
          position
          phone
          firstName
          lastName
          avatar
          confirmed
          departamentUuid
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export interface ITicket {
  id: number;
  ticketUuid: string;
  checked: boolean;
  inside: boolean;
  active: boolean;
  registration: boolean;
  createdAt: string;
  ticketSegmentId: number;
  user: IUser;
}
export interface ISegmentResponse {
  getTicketSegmentById: { tickets: ITicket[] };
}

export const useFetchGetTicketsSegment = (id: number, pause: boolean) => {
  const [result, reexecuteQuery] = useQuery<ISegmentResponse>({
    query: GET_TICKETS_BY_SEGMENT_QUERY,
    variables: { id },
    pause: !pause,
    requestPolicy: 'network-only',
  });
  return {
    data: result.data,
    fetching: result.fetching,
    error: result.error,
    refetch: reexecuteQuery,
  };
};
