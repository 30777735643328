import stylex from '@stylexjs/stylex';
import { FC } from 'react';
import { styles } from './styles';

import { Button } from '@sminex/sminex-uikit';
import { EmptyCard } from '@shared/ui/empty-card';
import { OrganizerList } from '../organizer-list';

import ButtonWithArrows from '@shared/ui/button-with-arrows';
import { IOrganizer } from '../organizer/model/types';
import { AddButton } from '@features/add-button';

interface OranizerTabProps {
  organizers: IOrganizer[];
  handleChange: (organizer: IOrganizer) => void;
  handleDelete: (organizer: IOrganizer) => void;
  handleAdd: () => void;
  sendTickets: (users: number[]) => void;
  setOrganizers: (organizers: IOrganizer[]) => void;
  sortDirection: 'asc' | 'desc';
  setSortDirection: (direction: 'asc' | 'desc') => void;
}

export const OranizerTab: FC<OranizerTabProps> = (props) => {
  const {
    organizers,
    handleChange,
    handleDelete,
    handleAdd,
    sendTickets,
    setOrganizers,
    setSortDirection,
    sortDirection,
  } = props;

  const handleSort = () => {
    const sortedOrganizers = [...organizers].sort((a, b) => {
      const lastNameA = a.lastName.toLowerCase();
      const lastNameB = b.lastName.toLowerCase();

      if (lastNameA < lastNameB) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (lastNameA > lastNameB) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
    setOrganizers(sortedOrganizers);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const handleSendQrCodes = () => {
    organizers.filter((o) => o.access);
    if (organizers.length) {
      sendTickets(organizers.filter((o) => o.access).map((user) => user.id as number));
    }
  };

  return (
    <>
      {organizers.length == 0 ? (
        <EmptyCard handleAdd={handleAdd} />
      ) : (
        <>
          <div {...stylex.props(styles.buttonsContainer)}>
            <Button onClick={() => handleSendQrCodes()} size="small" variant="secondary">
              Отправить QR-код
            </Button>
          </div>
          <div {...stylex.props(styles.infoContainer)}>
            <ButtonWithArrows onClick={handleSort} children="Организаторы"></ButtonWithArrows>
            <div {...stylex.props(styles.accessText)}>Доступ к мероприятию</div>
          </div>
          <OrganizerList organizers={organizers} onChange={(e) => handleChange(e)} onDelete={handleDelete} />
          <AddButton
            onClick={handleAdd}
            settings={{
              fontSize: '14px',
            }}
          >
            Добавить организатора
          </AddButton>
        </>
      )}
    </>
  );
};
