import { anatomy } from '@shared/tokens/anatomy.stylex';
import { colors } from '@shared/tokens/colors.stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
import { typography } from '@shared/tokens/typography.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  mainContainer: {
    width: '100%',
  },
  parentContainer: {
    display: 'flex',
    maxWidth: '954px',
    justifyContent: 'space-between',
    padding: '12px 16px 12px 12px',
    gap: anatomy['size-24'],
    borderRadius: anatomy['size-8'],
    backgroundColor: colors.brandGhostBackground,
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: anatomy['size-12'],
    flex: '1 0 0',
  },
  icon: {
    display: 'flex',
    border: 'none',
    background: 'transparent',
    padding: '0',
    cursor: 'pointer',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
    padding: '12px 16px 12px 12px',
    alignSelf: 'stretch',
  },
  title: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-1'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightBody-4'],
    textAlign: 'left',
  },

  childContainer: {
    marginTop: anatomy['size-8'],
  },
});
