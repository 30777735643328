import { Client, Provider, cacheExchange, fetchExchange } from 'urql';
import { FC, ReactNode } from 'react';
import { getJWTTokens } from '../shared/libs/local-storage';
type TUrQlProvider = {
  children?: ReactNode;
};

const API_URL = import.meta.env.VITE_API_URL;

const UR_QL_CLIENT = new Client({
  url: `${API_URL}/api/v1`,
  exchanges: [cacheExchange, fetchExchange],
  fetchOptions: () => {
    const { accessToken } = getJWTTokens(); // TODO: useAuth
    return {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  },
});

export const UrQlProvider: FC<TUrQlProvider> = ({ children }) => {
  return <Provider value={UR_QL_CLIENT}>{children}</Provider>;
};
