import stylex from '@stylexjs/stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: fonts.din2014Regular,
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px',
    alignSelf: 'stretch',
    background: colors.brandGhostBackground,
    borderRadius: '8px',
    padding: '12px 16px',
  },

  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  title: {
    paddingLeft: '8px',
  },

  rightRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
  },

  action: {
    cursor: 'pointer',
  },
});
