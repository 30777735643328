import { colors } from '@shared/tokens/colors.stylex.ts';
import React from 'react';

interface PlusIconProps {
  fillColor?: string;
}

const ArrowsIcon: React.FC<PlusIconProps> = ({ fillColor = colors.secondaryText }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.48483 1.23483C3.63128 1.08839 3.86872 1.08839 4.01517 1.23483L6.26516 3.48483C6.41161 3.63128 6.41161 3.86872 6.26516 4.01517C6.11872 4.16161 5.88128 4.16161 5.73484 4.01517L4.125 2.40533V8.25C4.125 8.45711 3.95711 8.625 3.75 8.625C3.54289 8.625 3.375 8.45711 3.375 8.25V2.40533L1.76517 4.01517C1.61872 4.16161 1.38128 4.16161 1.23483 4.01517C1.08839 3.86872 1.08839 3.63128 1.23483 3.48483L3.48483 1.23483ZM8.25 3.375C8.45711 3.375 8.625 3.54289 8.625 3.75L8.625 9.59467L10.2348 7.98484C10.3813 7.83839 10.6187 7.83839 10.7652 7.98484C10.9116 8.13128 10.9116 8.36872 10.7652 8.51516L8.51516 10.7652C8.44484 10.8355 8.34946 10.875 8.25 10.875C8.15054 10.875 8.05516 10.8355 7.98483 10.7652L5.73484 8.51516C5.58839 8.36872 5.58839 8.13128 5.73484 7.98483C5.88128 7.83839 6.11872 7.83839 6.26517 7.98483L7.875 9.59467L7.875 3.75C7.875 3.54289 8.04289 3.375 8.25 3.375Z"
        fill={fillColor}
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default ArrowsIcon;
