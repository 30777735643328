import { typography } from '@shared/tokens/typography.stylex';
import { colors } from '../../shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';
import { fonts } from '@shared/tokens/fonts.stylex';

export const styles = stylex.create({
  buttonText: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-1'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightCaption-1'],
    color: colors.primaryInverseText,
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
});
