import { useMutation } from 'urql';
import { gql } from 'urql';

export const DELETE_USER_TO_GROUP_MUTATION = gql`
  mutation ($userId: [Int!], $groupId: [Int!]) {
    deleteUserFromGroup(userId: $userId, groupId: $groupId) {
      success
      rowsAffected
    }
  }
`;

export interface IDeleteUserToGroupVariables {
  userId: number;
  groupId: number;
}

export interface IDeleteUserToGroupResponse {
  deleteUserFromGroup: {
    rowsAffected: number;
    success: boolean;
  };
}

export const useFetchDeleteOrganizer = () => {
  return useMutation(DELETE_USER_TO_GROUP_MUTATION);
};
