import React from 'react';
import EventCard from '../../entities/event-card';
import { CardDetails } from '@shared/types/types';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { useNavigate } from 'react-router-dom';
interface EventCardSwitcherProps {
  cardDetails?: CardDetails;
}

const EventCardSwitcher: React.FC<EventCardSwitcherProps> = ({ cardDetails }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    cardDetails && navigate(`/events/${cardDetails.id}`);
  };

  return (
    <div {...stylex.props(styles.card)} onClick={() => handleClick()}>
      <EventCard isSkeleton={cardDetails ? false : true} cardDetails={cardDetails} />
    </div>
  );
};

export default EventCardSwitcher;
