import { typography } from '@shared/tokens/typography.stylex';
import { anatomy } from '../../shared/tokens/anatomy.stylex';
import { colors } from '../../shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';
import { fonts } from '@shared/tokens/fonts.stylex';

export const styles = stylex.create({
  base: {
    width: '620px',
    backgroundColor: colors['primaryBackground'],
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    borderRadius: anatomy['borderRadiusL'],
    fontFamily: fonts.din2014Regular,
  },
  input: {
    width: '100%',
  },
  detailsСontainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: anatomy['size-16'],
  },
  title: {
    fontSize: typography['fontSizeCaption-2'],
    fontWeight: typography['fontWeighRegular'],
    lineHeight: typography['lineHeightCaption-2'],
    color: colors['tertiaryText'],
    paddingBottom: anatomy['size-8'],
  },
  tagContainer: {
    display: 'flex',
    gap: anatomy['size-4'],
    flexWrap: 'wrap',
  },
  tag: {
    display: 'flex',
    gap: anatomy['size-8'],
    padding: `${anatomy['size-4']} ${anatomy['size-4']} ${anatomy['size-4']} ${anatomy['size-8']}`,
    backgroundColor: colors['ghostBackground'],
    borderRadius: anatomy['borderRadiusXs'],
    alignItems: 'center',

    fontSize: typography['fontSizeCaption-2'],
    fontWeight: typography['fontWeighRegular'],
    lineHeight: typography['lineHeightCaption-2'],
    color: colors['secondaryText'],
  },
  tagImg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: anatomy['size-16'],
    height: anatomy['size-16'],
    cursor: 'pointer',
  },
  plusIconTag: {
    fill: colors['secondaryIcon'],
    transform: 'rotate(45deg)',
    ':hover': {
      fill: colors['primaryIcon'],
    },
  },
  infoAvailable: {
    fontSize: typography['fontSizeBody-4'],
    fontWeight: typography['fontWeighRegular'],
    lineHeight: typography['lineHeightBody-3'],
    color: colors['secondaryText'],
    textAlign: 'center',
  },
  contentButtonChildren: {
    display: 'flex',
    alignItems: 'center',
    gap: anatomy['size-8'],
  },
  textButton: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeBody-4'],
    fontWeight: typography['fontWeighRegular'],
    lineHeight: typography['lineHeightCaption-1'],
    textAlign: 'left',
    color: colors.accentText,
  },

  /**
   * Стили для блока с выбранными участниками
   */

  oneTag: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 4px 4px 8px',
    gap: '8px',
    borderRadius: anatomy.borderRadiusXs,
    backgroundColor: colors.ghostBackground,
    width: 'min-content',
  },
  textInTag: {
    maxWidth: '210px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: colors.secondaryText,
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-2'],
    fontStyle: 'normal',
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightCaption-2'],
  },
  xMarkIcon: {
    fill: colors.secondaryIcon,
    cursor: { default: 'pointer' },
  },
  pencilIcon: {
    fill: colors.accentIcon,
  },
  titleWithTagBlock: {
    display: 'flex',
    width: '596px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
  },
  titleTagBlock: {
    color: colors.tertiaryText,
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-2'],
    fontStyle: 'normal',
    fontWeight: typography.fontWeighRegular,
    lineHeight: '12px',
  },
  tagBlock: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    gap: '4px',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
  },

  inputWithXmark: {
    position: 'relative',
  },
  xMarkIconInput: {
    position: 'absolute',
    right: '16px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
});
