import { Button, Input, PreloaderCircular, Switcher } from '@sminex/sminex-uikit';
import stylex from '@stylexjs/stylex';
import { FC, useEffect, useState } from 'react';
import { styles } from './styles';

import { REQURED_PHRASE_MESSAGE, emailValidator, phraseValidator } from '@pages/event/lib/validator';
import { phoneNumberValidator } from '@shared/libs/phone-validator';
import ModalWrapper from '@shared/modal-wrapper';
import { IOrganizer } from '../organizer/model/types';

interface IModalProps {
  closeHandle: () => void;
  addOrganizer: (organizer: Omit<IOrganizer, 'id'>) => void;
  active: boolean;
}
// const dto = (user: IUser): IOrganizer => {
//   return { id: user.id, email: user.email, firstName: user.firstName, lastName: user.lastName, access: true };
// };

export const ModalAddOrganizer: FC<IModalProps> = ({ active, closeHandle, addOrganizer }) => {
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [addBtnDisabled, setAddBtnDisabled] = useState(true);
  const [phone, setPhone] = useState('');
  const [phoneErrors, setPhoneErrors] = useState<string | false>(false);
  const [emailErrors, setEmailErrors] = useState<string | false>(false);
  const [lastNameErrors, setLastNameErrors] = useState<string | false>(false);
  const [firstNameErrors, setFirstNameErrors] = useState<string | false>(false);
  const [access, setAccess] = useState(true);

  const handleChangeNumber = (value: string) => {
    const formattedNumber = phoneNumberValidator(value);
    setPhone(formattedNumber);
  };
  useEffect(() => {
    if (email) {
      emailValidator(email).then(setEmailErrors);
    }
  }, [email]);

  useEffect(() => {
    if (phone && phone.length < 17) {
      setPhoneErrors(`${REQURED_PHRASE_MESSAGE}`);
    } else {
      setPhoneErrors(false);
    }
  }, [phone]);

  useEffect(() => {
    if (lastName) {
      phraseValidator(lastName).then(setLastNameErrors);
    }
  }, [lastName]);

  useEffect(() => {
    if (firstName) {
      phraseValidator(firstName).then(setFirstNameErrors);
    }
  }, [firstName]);

  useEffect(() => {
    const isDisabled =
      !!lastNameErrors || !!firstNameErrors || !!phoneErrors || !!emailErrors || !lastName || !firstName || !phone;
    setAddBtnDisabled(isDisabled);
  }, [lastNameErrors, firstNameErrors, phoneErrors, emailErrors]);

  useEffect(() => {
    setLastName('');
    setFirstName('');
    setPhone('');
    setAccess(true);
    setEmail('');
    setPhoneErrors(false);
    setEmailErrors(false);
    setLastNameErrors(false);
    setFirstNameErrors(false);
    setIsLoading(false);
    setAddBtnDisabled(true);
  }, [active]);

  const handleClick = () => {
    addOrganizer({ lastName, firstName, phone, access: true, email });
  };

  if (!active) return;
  return (
    <ModalWrapper active={active} settingsContainer={{ height: 'auto', width: '600px' }}>
      <div {...stylex.props(styles.container)}>
        <p {...stylex.props(styles.header)}>Добавить организатора </p>
        <div {...stylex.props(styles.input)}>
          <Input
            placeholder="Введите фамилию"
            inputMessage={lastNameErrors || ''}
            status={lastNameErrors ? 'error' : undefined}
            settings={{ width: 'auto' }}
            value={lastName}
            changeFunction={setLastName}
            labelText="Фамилия организатора *"
          />
        </div>
        <div {...stylex.props(styles.input)}>
          <Input
            placeholder="Введите имя"
            inputMessage={firstNameErrors || ''}
            status={firstNameErrors ? 'error' : undefined}
            settings={{ width: 'auto' }}
            value={firstName}
            changeFunction={setFirstName}
            labelText="Имя организатора*"
          />
        </div>
        <div {...stylex.props(styles.emailAndPhone)}>
          <div {...stylex.props(styles.emailAndPhoneInput)}>
            <Input
              placeholder="Введите email"
              inputMessage={emailErrors || ''}
              status={emailErrors ? 'error' : undefined}
              settings={{ width: 'auto' }}
              value={email}
              changeFunction={setEmail}
              labelText="Email *"
            />
          </div>
          <div {...stylex.props(styles.emailAndPhoneInput)}>
            <Input
              placeholder="Введите номер телефона"
              value={phone}
              inputMessage={phoneErrors || ''}
              status={phoneErrors ? 'error' : undefined}
              settings={{ width: 'auto' }}
              changeFunction={handleChangeNumber}
              labelText="Телефон * "
            />
          </div>
        </div>
        <div {...stylex.props(styles.row)}>
          <Switcher isActive={access} changeFunction={() => setAccess(!access)} size="small" variant="default" />
          <p {...stylex.props(styles.switcherLabel)}>Доступ к мероприятию</p>
        </div>
        <div {...stylex.props(styles.actions)}>
          <Button onClick={() => closeHandle()} variant="secondary">
            Отмена
          </Button>
          <Button onClick={handleClick} variant="accent" isDisabled={addBtnDisabled}>
            {isLoading ? <PreloaderCircular variant="secondary" size="medium"></PreloaderCircular> : 'Добавить'}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};
