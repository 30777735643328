import { Button, Dropdown, Modal, PreloaderCircular, TextField } from '@sminex/sminex-uikit';
import { createPortal } from 'react-dom';
import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { FC, useEffect, useState } from 'react';
import { palette } from '@shared/tokens/palette.stylex';

interface IModalProps {
  closeHandle: () => void;
  sendTickets: (message: string) => void;
  active: boolean;
  isLoading: boolean;
}

const invitationTypes = [
  { id: 1, label: 'СМС', value: 'type1' },
  { id: 2, label: 'Email', value: 'type2' },
  { id: 3, label: 'СМС и Email', value: 'type3' },
];

const LENS_MESSAGE = 70;
export const ModalTickets: FC<IModalProps> = ({ active, closeHandle, sendTickets, isLoading }) => {
  const [message, setMessage] = useState('');
  const [selectedType] = useState(invitationTypes[0]);
  useEffect(() => {
    setMessage('');
  }, [active]);

  const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length <= LENS_MESSAGE) {
      setMessage(event.target.value);
    }
  };

  const handleClick = () => {
    if (message) {
      sendTickets(message);
    }
  };

  if (!active) return;
  return createPortal(
    <Modal
      active={active}
      settingsContainer={{ height: 'auto', width: '480px', padding: '0px' }}
      settingsHeader={{ padding: '0px' }}
      settingsFooter={{ padding: '0px' }}
    >
      {isLoading ? (
        <div {...stylex.props(styles.container)}>
          <div {...stylex.props(styles.header)}>Отправка приглашения</div>
          <div {...stylex.props(styles.loader)}>
            <PreloaderCircular variant="secondary" size="large" />
          </div>
        </div>
      ) : (
        <div {...stylex.props(styles.container)}>
          <p {...stylex.props(styles.header)}>Отправить билеты</p>
          <div {...stylex.props(styles.inputWrapper)}>
            <Dropdown
              labelMessage="Тип приглашения *"
              options={invitationTypes}
              itemText="label"
              itemValue="value"
              value={selectedType}
              settings={{ width: '100%', cursor: 'text' }}
            />
            <TextField
              labelMsg=""
              changeFunction={changeHandler}
              placeholder=""
              value={message}
              settings={{
                width: '100%',
                height: '200px',
                cursor: 'text',
                background: palette['orchid-50'],
                border: `1px dashed ${palette['orchid-500']}`,
              }}
              status={message.length < LENS_MESSAGE ? 'default' : 'error'}
              textareaMessage={
                message.length < LENS_MESSAGE ? String(message.length) : 'Максимальное колличество символов'
              }
            />
          </div>
          <div {...stylex.props(styles.actions)}>
            <Button onClick={() => closeHandle()} variant="secondary" settings={{ padding: '8px 20px' }} size="small">
              Отмена
            </Button>
            <Button onClick={handleClick} variant="accent" settings={{ padding: '8px 20px' }} size="small">
              Отправить
            </Button>
          </div>
        </div>
      )}
    </Modal>,
    document.getElementById('modal') as Element
  );
};
