import { gql, useMutation } from 'urql';
export const CREATE_TICKETS = gql`
  mutation createTickets($usersId: [Int!], $eventId: Int!, $segmentId: Int!) {
    createTicket(
      input: { checked: false, inside: false, active: true, registration: false }
      userId: $usersId
      eventId: $eventId
      segmentId: $segmentId
    ) {
      id
      ticketUuid
      checked
      inside
      active
      registration
      createdAt
      updatedAt
      ticketSegmentId
    }
  }
`;

export const useCreateTickets = () => {
  const [{ data, error }, executeMutation] = useMutation(CREATE_TICKETS);

  const createTickets = (variables: { usersId: number[]; eventId: number; segmentId: number }) => {
    return executeMutation(variables);
  };
  return { data, error, createTickets };
};
