import { Tab } from '@sminex/sminex-uikit';
import { FC } from 'react';

import stylex from '@stylexjs/stylex';
import { styles } from './styles';

export interface ITab {
  id: number;
  title: string;
}

interface ITabsProps {
  activeTab: ITab;
  tabs: ITab[];
  size?: 'medium' | 'large';
  onChange: (tab: ITab) => void;
}

export const Tabs: FC<ITabsProps> = ({ activeTab, size = 'medium', onChange, tabs }) => {
  return (
    <div {...stylex.props(styles.row)}>
      {tabs.map((tab) => (
        <Tab
          key={tab.id}
          size={size}
          title={tab.title}
          isActive={activeTab.id === tab.id}
          changeFunction={() => onChange(tab)}
        />
      ))}
      <div {...stylex.props(styles.bordered)}></div>
    </div>
  );
};
