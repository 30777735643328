import { ButtonProps } from '@sminex/sminex-uikit/dist/src/ui-components/button/model/types';
import { FC, ReactNode } from 'react';

import stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';

interface IIconProps extends Omit<ButtonProps, 'children'> {
  icon?: ReactNode;
  children?: ReactNode;
  iconPosition?: 'left' | 'right';
}

export const IconButton: FC<IIconProps> = ({ icon, children, onClick, settings, iconPosition = 'left' }, props) => {
  return (
    <a onClick={onClick} {...stylex.props(styles.button)} settings={settings} {...props}>
      {iconPosition === 'left' && icon}
      {children}
      {iconPosition === 'right' && icon}
    </a>
  );
};
