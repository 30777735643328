import stylex from '@stylexjs/stylex';
import { colors } from '@shared/tokens/colors.stylex';
export const styles = stylex.create({
  container: {
    backgroundColor: colors.primaryBackground,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '64px 80px',
    minHeight: '100vh',
    gap: '24px',
  },
});
