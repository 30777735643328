import { fonts } from '@shared/tokens/fonts.stylex';
import { anatomy } from '@shared/tokens/anatomy.stylex';
import { colors } from '@shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';
import { typography } from '@shared/tokens/typography.stylex';

// const slideAnimation = stylex.keyframes({
//   '0%': { transform: 'translateX(0)' },
//   '100%': { transform: 'translateX(100%)' },
// });

export const styles = stylex.create({
  switcher: {
    //     width: Fill (546px)px;
    // height: Hug (44px)px;
    // padding: 4px 0px 0px 0px;
    // gap: 4px;
    // border: 1px 0px 0px 0px;
    // opacity: 0px;

    display: 'flex',
    justifyContent: 'space-around',
    padding: '4px',
    borderRadius: anatomy.borderRadiusS,
    alignItems: 'center',
    width: '546px',
    height: '44px',
    backgroundColor: colors.primaryBackground,
    border: '1px solid',
    borderColor: colors.tertiaryBorder,
    color: colors.primaryText,
  },
  switcherBlock: {
    height: '100%',
    flex: '1',
    textAlign: 'center',
    cursor: 'pointer',
    alignContent: 'center',
    transition: 'background-color 0.4s ease',
    borderRadius: anatomy.borderRadiusXs,
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-1'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightCaption-1'],
  },
  active: {
    backgroundColor: colors.primaryInverseBackground,
    color: colors.primaryInverseText,
  },
});
