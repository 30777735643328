import stylex from '@stylexjs/stylex';

import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';

export const styles = stylex.create({
  container: {
    backgroundColor: colors.primaryBackground,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '64px 80px',
    minHeight: '100vh',
    gap: '24px',
  },

  base: {
    width: '954px',
    fontFamily: fonts.din2014Regular,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  header: {
    padding: 0,
    color: colors.primaryText,
  },

  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  column: { display: 'flex', flexDirection: 'column', alignItems: 'start' },

  switcherLabel: {
    fontSize: '14px',
    paddingLeft: '8px',
    color: colors.primaryText,
  },

  dateLabel: {
    fontSize: '14px',
    fonWeight: '400',
    paddingLeft: '16px',
    color: colors.ghostText,
  },
  tabs: {
    width: 'auto',
  },
  menuItem: {
    color: colors.primaryText,
    fontSize: '14px',
  },

  imageWrapper: {
    marginTop: '24px',
    width: '300px',
    height: '210px',
    borderRadius: '8px',
    border: `1px solid ${colors.ghostBorder}`,
    // background: `url('${img}') lightgray 0px 2.999px / 100% 95.391% no-repeat, #F6F6F6`,
  },

  editButton: {
    paddingTop: '10px',
    color: colors.accentText,
    fontSize: '12px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
});
