import { Switcher } from '@sminex/sminex-uikit';
import stylex from '@stylexjs/stylex';
import { FC } from 'react';
import { styles } from './styles';

import { IOrgnizerProps } from '../model/types';

export const Organizer: FC<IOrgnizerProps> = ({ organizer, onChange, actions }) => {
  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.row)}>
        <p {...stylex.props(styles.title)}>
          {organizer.lastName} {organizer.firstName}
        </p>
      </div>
      <div {...stylex.props(styles.rightRow)}>
        <Switcher
          size="small"
          changeFunction={() => onChange({ ...organizer, access: !organizer.access })}
          isActive={organizer.access}
        />
        {actions}
      </div>
    </div>
  );
};
