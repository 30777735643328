import { FC } from 'react';

const SminexIcon: FC = () => {
  return (
    <svg width="120" height="28" viewBox="0 0 120 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M101.363 27.2789L108.777 18.4485L101.635 8.30373H105.512C107.416 8.30373 107.144 9.07891 110.443 13.7637L111.566 15.3815L116.055 8.53965C116.191 8.33743 116.429 8.27002 116.633 8.27002H118.946L112.212 16.2915L120 27.2789H116.361C113.946 27.3126 114.388 26.3352 111.192 21.8526L109.423 19.3585L104.288 26.9755C104.152 27.1778 103.948 27.2452 103.675 27.2452H101.363V27.2789Z"
        fill="#F5F5F5"
      />
      <path
        d="M93.5747 27.6493C90.956 27.6493 88.2352 26.9078 86.5347 24.8182C84.9363 22.8634 84.3581 20.4367 84.2901 17.9763C84.2561 14.9093 85.0383 12.4489 86.6708 10.5952C88.3032 8.74155 90.5479 7.83154 93.4047 7.83154C94.765 7.83154 95.9894 8.03376 97.0437 8.4382C98.098 8.84265 98.9142 9.38192 99.5604 10.0897C100.207 10.7975 100.683 11.6064 100.989 12.5163C101.295 13.4263 101.465 14.4375 101.499 15.516C101.499 16.0889 101.465 16.6282 101.363 17.1C101.363 17.1675 101.295 17.2012 101.261 17.2012H89.2895C89.3575 20.2682 89.8337 22.4589 90.7179 23.8408C91.6022 25.2226 93.0646 25.8967 95.1391 25.8967C97.2817 25.8967 98.9482 25.29 100.581 24.043C100.853 24.2789 101.159 24.9193 100.581 25.4249C99.0842 26.773 96.1594 27.6493 93.5747 27.6493ZM89.2555 16.2912L97.0097 15.7182C97.0437 13.1567 96.9416 8.94376 93.5067 8.94376C89.9017 8.94376 89.3575 13.5612 89.2555 16.2912Z"
        fill="#F5F5F5"
      />
      <path
        d="M81.2971 25.0883V13.9661C81.2971 10.1576 79.8347 7.86572 76.0597 7.86572C73.4069 7.86572 70.8902 9.11275 69.0877 10.9665C69.0877 9.48349 68.8497 8.27016 67.3873 8.27016H64.3264L64.4965 10.4272V25.0546L64.3264 27.2453H69.2918L69.1217 25.0546V11.9776C70.5501 10.899 71.8765 10.3598 73.781 10.3598C76.1957 10.3598 76.6718 12.5842 76.6718 15.0446V24.6838C76.6718 26.3016 77.0459 27.2453 78.4063 27.2453H81.4672L81.2971 25.0883Z"
        fill="#F5F5F5"
      />
      <path
        d="M59.5311 2.77677C59.5311 4.12491 58.4087 5.20343 57.0484 5.20343C55.688 5.20343 54.5657 4.12491 54.5657 2.77677C54.5657 1.42862 55.688 0.350098 57.0484 0.350098C58.4087 0.350098 59.5311 1.42862 59.5311 2.77677Z"
        fill="#F5F5F5"
      />
      <path
        d="M54.7358 8.27002L54.9059 10.4271V25.0545L54.7358 27.2452H59.7012L59.5312 25.0545V10.7978C59.5312 9.34854 59.2591 8.23633 57.7967 8.23633H54.7358V8.27002Z"
        fill="#F5F5F5"
      />
      <path
        d="M50.0426 25.0883V13.9661C50.0426 10.1239 48.5802 7.86572 44.8052 7.86572C41.6423 7.86572 39.4997 9.44979 37.7992 11.2361C37.187 9.11276 35.6906 7.86572 32.8338 7.86572C30.1471 7.86572 27.9705 9.11275 26.202 11.0002V10.9665C26.202 9.48349 25.9639 8.27016 24.5015 8.27016H21.4407L21.6107 10.4272V25.0546L21.4407 27.2453H26.4061L26.236 25.0546V11.9776C27.6304 10.8316 29.2969 10.3598 30.6232 10.3598C33.242 10.3598 33.514 13.0224 33.514 15.0446V25.0546L33.344 27.2453H38.3094L38.1393 25.0546V11.9776C39.3296 10.9665 40.9961 10.3598 42.5605 10.3598C45.2813 10.3598 45.4513 13.292 45.4513 15.0446V24.6838C45.4513 26.5038 45.9615 27.2453 47.1858 27.2453H50.2467L50.0426 25.0883Z"
        fill="#F5F5F5"
      />
      <path
        d="M8.89106 27.6501C6.61243 27.6501 3.2455 27.0097 1.20493 25.8638C1.57903 25.0212 2.29323 24.3808 3.31351 24.9201C5.15002 25.8638 6.98653 26.3019 9.0271 26.3019C11.9519 26.3019 14.0945 24.6841 13.9585 21.7519C13.6524 15.6179 0.932856 14.7079 0.966865 7.32676C1.00087 1.90047 5.93224 0.350098 10.6596 0.350098C12.9382 0.350098 15.1828 0.788253 17.3934 1.66455C17.0193 2.54084 16.3731 3.28232 15.2508 2.74306C13.6864 2.00158 12.122 1.66455 10.4555 1.66455C7.56469 1.66455 5.93224 3.21491 5.93224 5.50676C5.89823 11.3712 18.8218 12.2475 19.0599 19.8645C19.1959 25.4256 14.5026 27.6501 8.89106 27.6501Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};

export default SminexIcon;
