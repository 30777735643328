import * as stylex from '@stylexjs/stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';

export const styles = stylex.create({
  button: {
    cursor: 'pointer',
    fontFamily: fonts.din2014Regular,
    color: colors.primaryText,
    fontSize: '18px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    minWidth: '177px',
    padding: '8px 20px',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '2px ',
  },

  children: {
    color: colors.accentText,
  },
});
