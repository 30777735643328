import { FC } from 'react';

interface IChevronProps {
  color?: string;
}
export const Chevron: FC<IChevronProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.35355 10.8536C8.15829 11.0488 7.84171 11.0488 7.64645 10.8536L2.64645 5.85355C2.45119 5.65829 2.45119 5.34171 2.64645 5.14645C2.84171 4.95119 3.15829 4.95119 3.35355 5.14645L8 9.79289L12.6464 5.14645C12.8417 4.95118 13.1583 4.95118 13.3536 5.14645C13.5488 5.34171 13.5488 5.65829 13.3536 5.85355L8.35355 10.8536Z"
        fill="#000000"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export const ReversedChevron: FC<IChevronProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.35355 5.14645C8.15829 4.95119 7.84171 4.95119 7.64645 5.14645L2.64645 10.1464C2.45119 10.3417 2.45119 10.6583 2.64645 10.8536C2.84171 11.0488 3.15829 11.0488 3.35355 10.8536L8 6.20711L12.6464 10.8536C12.8417 11.0488 13.1583 11.0488 13.3536 10.8536C13.5488 10.6583 13.5488 10.3417 13.3536 10.1464L8.35355 5.14645Z"
        fill="#000000"
        fillOpacity="0.7"
      />
    </svg>
  );
};
