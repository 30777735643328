import { Modal } from '@sminex/sminex-uikit';
import React from 'react';
import { createPortal } from 'react-dom';

type ModalWrapperProps = React.ComponentProps<typeof Modal>;

const ModalWrapper: React.FC<ModalWrapperProps> = (props) => {
  return createPortal(<Modal {...props}></Modal>, document.getElementById('modal') as Element);
};

export default ModalWrapper;
