import { Checkbox, Input } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import { useEffect, useState } from 'react';
import './styles.css';
import { IUser, useFetchUsers } from '@shared/hooks/useFetchUsers.ts';
import { IInvitedParticipants } from '@entities/segments-tab/index.tsx';

interface CompaniesStructureProps {
  invitedParticipants: IInvitedParticipants[];
  setSelectedParticipants: (users: number[]) => void;
  selectedParticipants: number[];
  setParticipantsDelete: React.Dispatch<React.SetStateAction<IInvitedParticipants[]>>;
  participantsDelete: IInvitedParticipants[];
}

interface Participant {
  id: number;
  firstName: string;
  lastName: string;
  avatar: null | string;
}

const CompaniesStructure: React.FC<CompaniesStructureProps> = ({
  setSelectedParticipants,
  invitedParticipants = [],
  selectedParticipants,
  setParticipantsDelete,
  participantsDelete,
}) => {
  const { userData } = useFetchUsers();

  const [inputText, setInputText] = useState('');
  const [filteredData, setFilteredData] = useState<IUser[]>([]);

  useEffect(() => {
    if (userData && userData.data) {
      setFilteredData(userData.data);
    }
  }, [userData]);

  const handleCheck = async (participant: Participant) => {
    const invitedParticipant = invitedParticipants.find((el) => el.userId === participant.id);
    if (invitedParticipant) {
      if (participantsDelete.find((el) => el.userId === participant.id)) {
        setParticipantsDelete(participantsDelete.filter((elem) => elem.userId !== participant.id));
      } else {
        setParticipantsDelete([...participantsDelete, invitedParticipant]);
      }
    } else {
      if (selectedParticipants.includes(participant.id)) {
        setSelectedParticipants(selectedParticipants.filter((el) => el !== participant.id));
      } else {
        setSelectedParticipants([...selectedParticipants, participant.id]);
      }
    }
  };

  const statusAllChecked = userData && userData.data.length === selectedParticipants.length;

  const handleAllCheck = () => {
    if (statusAllChecked) {
      setSelectedParticipants([]);
      setParticipantsDelete([]);
    } else {
      const newCheckedUsers = userData?.data.map((participant: Participant) => participant.id) || [];
      setSelectedParticipants(newCheckedUsers);
    }
  };

  const handleInput = (text: string) => {
    setInputText(text);
    const lookingText = text.trim().toLocaleLowerCase();

    const filtered =
      userData &&
      userData.data.filter(
        (participant: Participant) =>
          participant.firstName.trim().toLowerCase().includes(lookingText) ||
          participant.lastName.trim().toLowerCase().includes(lookingText)
      );
    setFilteredData(filtered || []);
  };

  const selectedParticipantsCount = [
    ...new Set([
      ...invitedParticipants.filter((id) => !participantsDelete.includes(id)).map((el) => el.userId),
      ...selectedParticipants,
    ]),
  ];

  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.inputWithSelectedParticipant)}>
        <div {...stylex.props(styles.input)}>
          <Input
            type="search"
            status="default"
            placeholder="Поиск"
            value={inputText}
            size="small"
            settings={{ width: '546px' }}
            changeFunction={handleInput}
          />
        </div>
        <div {...stylex.props(styles.participant)}>
          Количество выбранных участников: {selectedParticipantsCount.length}
        </div>
      </div>
      <div {...stylex.props(styles.blockAllList)}>
        <div {...stylex.props(styles.checkboxWithName)}>
          <Checkbox onChange={handleAllCheck} size={'small'} isChecked={statusAllChecked} isIndeterminate={false} />
          <div {...stylex.props(styles.participant)}>Выбрать всех сотрудников</div>
        </div>

        <div className="custom-scroll">
          <div {...stylex.props(styles.allList)}>
            {filteredData.map((participant, index) => (
              <div key={index} {...stylex.props(styles.checkboxWithName)} onClick={() => handleCheck(participant)}>
                <Checkbox
                  onChange={() => {}}
                  size={'small'}
                  isChecked={
                    invitedParticipants.find((el) => el.userId === participant.id)
                      ? !participantsDelete.find((elem) => elem.userId === participant.id)
                      : selectedParticipants.includes(participant.id)
                  }
                />
                <div {...stylex.props(styles.participant)}>
                  {participant.firstName} {participant.lastName}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompaniesStructure;
