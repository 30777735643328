import React, { useCallback, ReactNode, useEffect, useState } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import ModalWrapper from '@shared/modal-wrapper/index.tsx';
import EmployeeSwitcher from '@entities/employee-switcher/index.tsx';
import CompaniesStructure from './companies-structure.tsx';
import { FreelanceEmployee } from './freelance-employee.tsx';
import WithExcel from './with-excel.tsx';
import { Segment } from '@entities/second-step-form/index.tsx';
import { IFreelanceEmployee } from '@shared/types/types.ts';

interface CreateSegmentModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (isOpenModal: boolean) => void;
  segment: Segment;
  onChange: (segment: Segment) => void;
}

const CreateSegmentModal: React.FC<CreateSegmentModalProps> = ({ isOpenModal, setIsOpenModal, segment, onChange }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [checkedState, setCheckedState] = useState<{ [key: string]: boolean }>({});
  const [freelanceEmployees, setFreelanceEmployees] = useState<IFreelanceEmployee[]>([]);

  // TODO: реализовать импорт Excel
  const onFileChange = (file: File) => {
    console.log(file);
  };
  //

  useEffect(() => {
    if (isOpenModal) {
      setCheckedState(segment.companiesStructureList);
      setFreelanceEmployees(segment.freelanceEmployee);
    } else {
      isOpenModal;
    }
  }, [segment, isOpenModal]);

  const handelChangeSegment = useCallback(() => {
    onChange({ ...segment, companiesStructureList: { ...checkedState }, freelanceEmployee: freelanceEmployees });
  }, [segment, checkedState, freelanceEmployees, onChange]);

  const hasEmptyFirstName = freelanceEmployees.some((employee) => employee.firstName.trim() === '');
  const hasEmptyLastName = freelanceEmployees.some((employee) => employee.lastName.trim() === '');
  const hasEmptyMail = freelanceEmployees.some((employee) => employee.email.trim() === '');
  const hasEmptyPhone = freelanceEmployees.some((employee) => employee.phone.trim() === '');
  const сonditionAdding = hasEmptyFirstName || hasEmptyLastName || hasEmptyMail || hasEmptyPhone;

  const checkingBeforeAdding = (boolean: boolean) => {
    if (сonditionAdding) {
      return;
    }
    setIsOpenModal(boolean);
    handelChangeSegment();
  };

  const formsForAdd: ReactNode[] = [
    <CompaniesStructure checkedState={checkedState} setCheckedState={setCheckedState} />,
    <FreelanceEmployee
      freelanceEmployees={freelanceEmployees}
      onChange={setFreelanceEmployees}
      сonditionAdding={сonditionAdding}
    />,
    <WithExcel onFileChange={onFileChange} />,
  ];

  return (
    <ModalWrapper
      active={isOpenModal}
      title={`Добавить участников: ${segment.name}`}
      size="small"
      settingsContainer={{ height: 'auto' }}
      settingsHeader={{ width: '596px' }}
      buttons={[
        {
          children: <p {...stylex.props(styles.textBtn, styles.cancelBtn)}>Отмена</p>,
          variant: 'secondary',
          onClick: () => setIsOpenModal(false),
          settings: { width: '97px' },
        },
        {
          children: <p {...stylex.props(styles.textBtn, styles.acceptBtn)}>Добавить</p>,
          variant: 'accent',
          onClick: () => {
            checkingBeforeAdding(false);
          },
          settings: { width: '97px' },
          isDisabled: сonditionAdding,
        },
      ]}
    >
      <EmployeeSwitcher
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        blocks={['Орг. структура', 'Внештатный участник', 'Импортировать из Excel']}
      />
      {formsForAdd[activeIndex]}
    </ModalWrapper>
  );
};

export default CreateSegmentModal;
