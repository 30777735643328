import { formatPhoneNumber } from '@entities/create-segment-modal/lib/form-validator';

export const phoneNumberValidator = (value: string) => {
  const replacedValue = String(value).replace(/\D/g, '');
  let formattedValue = '';

  if (replacedValue.length > 0) {
    // Добавляем логику для первой цифры
    if (replacedValue[0] === '7' || replacedValue[0] !== '8') {
      formattedValue = '8';
    }
    formattedValue += replacedValue;
  }
  // Обрезаем до 11 символов
  formattedValue = formattedValue.slice(0, 11);
  const maskedValue = formatPhoneNumber(formattedValue);
  return maskedValue;
};
