import * as stylex from '@stylexjs/stylex';
import type { VarGroup } from '@stylexjs/stylex/lib/StyleXTypes';
export type TPalette = Readonly<{
  'bronze-50': string;
  'bronze-100': string;
  'bronze-200': string;
  'bronze-300': string;
  'bronze-400': string;
  'bronze-500': string;
  'bronze-600': string;
  'bronze-700': string;
  'bronze-800': string;
  'bronze-900': string;
  'bronze-950': string;
  'grey-50': string;
  'grey-100': string;
  'grey-200': string;
  'grey-300': string;
  'grey-400': string;
  'grey-500': string;
  'grey-600': string;
  'grey-700': string;
  'grey-800': string;
  'grey-900': string;
  'grey-950': string;
  greyWhite: string;
  greyBlack: string;
  'lighted-5': string;
  'lighted-10': string;
  'lighted-20': string;
  'lighted-30': string;
  'lighted-40': string;
  'lighted-50': string;
  'lighted-60': string;
  'lighted-70': string;
  'lighted-80': string;
  'lighted-90': string;
  'lighted-95': string;
  'darked-5': string;
  'darked-10': string;
  'darked-20': string;
  'darked-30': string;
  'darked-40': string;
  'darked-50': string;
  'darked-60': string;
  'darked-70': string;
  'darked-80': string;
  'darked-90': string;
  'darked-95': string;
  'lime-50': string;
  'lime-100': string;
  'lime-200': string;
  'lime-300': string;
  'lime-400': string;
  'lime-500': string;
  'lime-600': string;
  'lime-700': string;
  'lime-800': string;
  'lime-900': string;
  'lime-950': string;
  'lemon-50': string;
  'lemon-100': string;
  'lemon-200': string;
  'lemon-300': string;
  'lemon-400': string;
  'lemon-500': string;
  'lemon-600': string;
  'lemon-700': string;
  'lemon-800': string;
  'lemon-900': string;
  'lemon-950': string;
  'yellow-50': string;
  'yellow-100': string;
  'yellow-200': string;
  'yellow-300': string;
  'yellow-400': string;
  'yellow-500': string;
  'yellow-600': string;
  'yellow-700': string;
  'yellow-800': string;
  'yellow-900': string;
  'yellow-950': string;
  'orange-50': string;
  'orange-100': string;
  'orange-200': string;
  'orange-300': string;
  'orange-400': string;
  'orange-500': string;
  'orange-600': string;
  'orange-700': string;
  'orange-800': string;
  'orange-900': string;
  'orange-950': string;
  'tangerine-50': string;
  'tangerine-100': string;
  'tangerine-200': string;
  'tangerine-300': string;
  'tangerine-400': string;
  'tangerine-500': string;
  'tangerine-600': string;
  'tangerine-700': string;
  'tangerine-800': string;
  'tangerine-900': string;
  'tangerine-950': string;
  'red-50': string;
  'red-100': string;
  'red-200': string;
  'red-300': string;
  'red-400': string;
  'red-500': string;
  'red-600': string;
  'red-700': string;
  'red-800': string;
  'red-900': string;
  'red-950': string;
  'rose-50': string;
  'rose-100': string;
  'rose-200': string;
  'rose-300': string;
  'rose-400': string;
  'rose-500': string;
  'rose-600': string;
  'rose-700': string;
  'rose-800': string;
  'rose-900': string;
  'rose-950': string;
  'aster-50': string;
  'aster-100': string;
  'aster-200': string;
  'aster-300': string;
  'aster-400': string;
  'aster-500': string;
  'aster-600': string;
  'aster-700': string;
  'aster-800': string;
  'aster-900': string;
  'aster-950': string;
  'orchid-50': string;
  'orchid-100': string;
  'orchid-200': string;
  'orchid-300': string;
  'orchid-400': string;
  'orchid-500': string;
  'orchid-600': string;
  'orchid-700': string;
  'orchid-800': string;
  'orchid-900': string;
  'orchid-950': string;
  'lilac-50': string;
  'lilac-100': string;
  'lilac-200': string;
  'lilac-300': string;
  'lilac-400': string;
  'lilac-500': string;
  'lilac-600': string;
  'lilac-700': string;
  'lilac-800': string;
  'lilac-900': string;
  'lilac-950': string;
  'iris-50': string;
  'iris-100': string;
  'iris-200': string;
  'iris-300': string;
  'iris-400': string;
  'iris-500': string;
  'iris-600': string;
  'iris-700': string;
  'iris-800': string;
  'iris-900': string;
  'iris-950': string;
  'blue-50': string;
  'blue-100': string;
  'blue-200': string;
  'blue-300': string;
  'blue-400': string;
  'blue-500': string;
  'blue-600': string;
  'blue-700': string;
  'blue-800': string;
  'blue-900': string;
  'blue-950': string;
  'cyan-50': string;
  'cyan-100': string;
  'cyan-200': string;
  'cyan-300': string;
  'cyan-400': string;
  'cyan-500': string;
  'cyan-600': string;
  'cyan-700': string;
  'cyan-800': string;
  'cyan-900': string;
  'cyan-950': string;
  'teal-50': string;
  'teal-100': string;
  'teal-200': string;
  'teal-300': string;
  'teal-400': string;
  'teal-500': string;
  'teal-600': string;
  'teal-700': string;
  'teal-800': string;
  'teal-900': string;
  'teal-950': string;
  'green-50': string;
  'green-100': string;
  'green-200': string;
  'green-300': string;
  'green-400': string;
  'green-500': string;
  'green-600': string;
  'green-700': string;
  'green-800': string;
  'green-900': string;
  'green-950': string;
}>;
export const palette: VarGroup<TPalette> = stylex.defineVars({
  'bronze-50': '#f8f6f2',
  'bronze-100': '#ede7db',
  'bronze-200': '#dcd1b9',
  'bronze-300': '#cbbc97',
  'bronze-400': '#b9a476',
  'bronze-500': '#ae955f',
  'bronze-600': '#917c49',
  'bronze-700': '#6f5f37',
  'bronze-800': '#4d4127',
  'bronze-900': '#2b2416',
  'bronze-950': '#242B30',
  'grey-50': '#f5f5f5',
  'grey-100': '#e8e9ea',
  'grey-200': '#d2d3d5',
  'grey-300': '#bbbec0',
  'grey-400': '#a5a8ab',
  'grey-500': '#8e9296',
  'grey-600': '#777c81',
  'grey-700': '#61666c',
  'grey-800': '#4a5157',
  'grey-900': '#343b42',
  'grey-950': '#1d252d',
  greyWhite: '#ffffff',
  greyBlack: '#101820',
  'lighted-5': 'rgba(255, 255, 255, 0.05)',
  'lighted-10': 'rgba(255, 255, 255, 0.10)',
  'lighted-20': 'rgba(255, 255, 255, 0.20)',
  'lighted-30': 'rgba(255, 255, 255, 0.30)',
  'lighted-40': 'rgba(255, 255, 255, 0.40)',
  'lighted-50': 'rgba(255, 255, 255, 0.50)',
  'lighted-60': 'rgba(255, 255, 255, 0.60)',
  'lighted-70': 'rgba(255, 255, 255, 0.70)',
  'lighted-80': 'rgba(255, 255, 255, 0.80)',
  'lighted-90': 'rgba(255, 255, 255, 0.90)',
  'lighted-95': 'rgba(255, 255, 255, 0.95)',
  'darked-5': 'rgba(29, 37, 45, 0.05)',
  'darked-10': 'rgba(29, 37, 45, 0.10)',
  'darked-20': 'rgba(29, 37, 45, 0.20)',
  'darked-30': 'rgba(29, 37, 45, 0.30)',
  'darked-40': 'rgba(29, 37, 45, 0.40)',
  'darked-50': 'rgba(29, 37, 45, 0.50)',
  'darked-60': 'rgba(29, 37, 45, 0.60)',
  'darked-70': 'rgba(29, 37, 45, 0.70)',
  'darked-80': 'rgba(29, 37, 45, 0.80)',
  'darked-90': 'rgba(29, 37, 45, 0.90)',
  'darked-95': 'rgba(29, 37, 45, 0.95)',
  'lime-50': '#f5faee',
  'lime-100': '#eef6e1',
  'lime-200': '#dfefc6',
  'lime-300': '#c8e39f',
  'lime-400': '#b2d877',
  'lime-500': '#9bcd4f',
  'lime-600': '#82b534',
  'lime-700': '#668e28',
  'lime-800': '#49661d',
  'lime-900': '#364b16',
  'lime-950': '#23310e',
  'lemon-50': '#fcfdef',
  'lemon-100': '#fafae1',
  'lemon-200': '#f5f6c3',
  'lemon-300': '#edef97',
  'lemon-400': '#e6e86b',
  'lemon-500': '#dee13f',
  'lemon-600': '#cacd20',
  'lemon-700': '#9ea119',
  'lemon-800': '#737512',
  'lemon-900': '#56570e',
  'lemon-950': '#393a09',
  'yellow-50': '#fefcea',
  'yellow-100': '#fdf9c9',
  'yellow-200': '#fcf097',
  'yellow-300': '#f8e064',
  'yellow-400': '#f3cd49',
  'yellow-500': '#e1b53f',
  'yellow-600': '#c18c30',
  'yellow-700': '#986523',
  'yellow-800': '#7d4f1f',
  'yellow-900': '#6a411c',
  'yellow-950': '#3d220c',
  'orange-50': '#f9f1e7',
  'orange-100': '#f6e9d9',
  'orange-200': '#f0d9bd',
  'orange-300': '#e6c094',
  'orange-400': '#dca86b',
  'orange-500': '#d39041',
  'orange-600': '#b6762b',
  'orange-700': '#8d5b21',
  'orange-800': '#644117',
  'orange-900': '#482f11',
  'orange-950': '#2d1d0a',
  'tangerine-50': '#fdf3ef',
  'tangerine-100': '#fae8e1',
  'tangerine-200': '#f6d3c3',
  'tangerine-300': '#efb297',
  'tangerine-400': '#e8916b',
  'tangerine-500': '#e1703f',
  'tangerine-600': '#cd5420',
  'tangerine-700': '#a14219',
  'tangerine-800': '#753012',
  'tangerine-900': '#57240e',
  'tangerine-950': '#3a1809',
  'red-50': '#fcf2f2',
  'red-100': '#f9e3e2',
  'red-200': '#f6cccb',
  'red-300': '#efa9a7',
  'red-400': '#e77975',
  'red-500': '#dd524c',
  'red-600': '#ca3a31',
  'red-700': '#aa2e26',
  'red-800': '#8c2822',
  'red-900': '#752522',
  'red-950': '#3f0f0d',
  'rose-50': '#fdf1f2',
  'rose-100': '#fae1e9',
  'rose-200': '#f6c3d3',
  'rose-300': '#ef97b2',
  'rose-400': '#e86b92',
  'rose-500': '#e13f71',
  'rose-600': '#cd2055',
  'rose-700': '#a11943',
  'rose-800': '#751231',
  'rose-900': '#570e24',
  'rose-950': '#3a0918',
  'aster-50': '#fbf2f8',
  'aster-100': '#fae1f5',
  'aster-200': '#f6c3ec',
  'aster-300': '#ef97dd',
  'aster-400': '#e86bcf',
  'aster-500': '#e13fc1',
  'aster-600': '#cd20ab',
  'aster-700': '#a11986',
  'aster-800': '#751261',
  'aster-900': '#570e49',
  'aster-950': '#3a0930',
  'orchid-50': '#faeffd',
  'orchid-100': '#f5e1fa',
  'orchid-200': '#ecc3f6',
  'orchid-300': '#de97ef',
  'orchid-400': '#d06be8',
  'orchid-500': '#c23fe1',
  'orchid-600': '#ac20cd',
  'orchid-700': '#8719a1',
  'orchid-800': '#621275',
  'orchid-900': '#490e57',
  'orchid-950': '#31093a',
  'lilac-50': '#f5effd',
  'lilac-100': '#ece1fa',
  'lilac-200': '#d9c3f6',
  'lilac-300': '#bd97ef',
  'lilac-400': '#a16be8',
  'lilac-500': '#853fe1',
  'lilac-600': '#6b20cd',
  'lilac-700': '#5419a1',
  'lilac-800': '#3d1275',
  'lilac-900': '#2d0e57',
  'lilac-950': '#1e093a',
  'iris-50': '#eff2fe',
  'iris-100': '#e1e4fa',
  'iris-200': '#c3c9f6',
  'iris-300': '#97a1ef',
  'iris-400': '#6b79e8',
  'iris-500': '#3f51e1',
  'iris-600': '#2033cd',
  'iris-700': '#1928a1',
  'iris-800': '#121d75',
  'iris-900': '#0e1657',
  'iris-950': '#090e3a',
  'blue-50': '#f0f6fe',
  'blue-100': '#deeafc',
  'blue-200': '#c4dafb',
  'blue-300': '#9dc4f8',
  'blue-400': '#70a4f3',
  'blue-500': '#4e82ee',
  'blue-600': '#3663e3',
  'blue-700': '#2a4fd0',
  'blue-800': '#2641a8',
  'blue-900': '#253a85',
  'blue-950': '#1a2551',
  'cyan-50': '#effdff',
  'cyan-100': '#d7f9fd',
  'cyan-200': '#b6f1fa',
  'cyan-300': '#8ae5f6',
  'cyan-400': '#64d0ea',
  'cyan-500': '#52b3d0',
  'cyan-600': '#418fae',
  'cyan-700': '#34728d',
  'cyan-800': '#2d5d73',
  'cyan-900': '#274d61',
  'cyan-950': '#163242',
  'teal-50': '#f2fdfa',
  'teal-100': '#d5faf1',
  'teal-200': '#aef4e4',
  'teal-300': '#86e7d4',
  'teal-400': '#68d1bf',
  'teal-500': '#55b5a6',
  'teal-600': '#439288',
  'teal-700': '#35746e',
  'teal-800': '#2b5d59',
  'teal-900': '#254d4a',
  'teal-950': '#122e2e',
  'green-50': '#f2fdf5',
  'green-100': '#e2fbe8',
  'green-200': '#c7f5d3',
  'green-300': '#9fecb1',
  'green-400': '#77db89',
  'green-500': '#5ec269',
  'green-600': '#4ca054',
  'green-700': '#3c7e44',
  'green-800': '#306339',
  'green-900': '#285231',
  'green-950': '#122d18',
});
