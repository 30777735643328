import { anatomy } from '@shared/tokens/anatomy.stylex';
import { colors } from '@shared/tokens/colors.stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    backgroundColor: colors.primaryBackground,
    fontFamily: fonts.din2014Regular,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '64px 80px',
    gap: '36px',
    height: '100%',
    alignSelf: 'stretch',
  },
  title: {
    color: colors.secondaryText,
    textAlign: 'center',
    fontSize: {
      default: anatomy['size-44'],
      '@media (max-width: 460px)': anatomy['size-40'],
    },
    width: {
      default: '415px',
      '@media (max-width: 460px)': '300px',
    },
  },
  subTitle: {
    fontSize: '18px',
    color: colors.secondaryText,
    textAlign: 'center',
    width: {
      default: '415px',
      '@media (max-width: 460px)': '300px',
    },
  },
  preloaderContainer: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
