import { Button } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';
import React from 'react';
import { colors } from '@shared/tokens/colors.stylex';

interface CreateTabProps {
  createHandler?: () => void;
}

const CreateTab: React.FC<CreateTabProps> = ({ createHandler }) => {
  return (
    <Button onClick={createHandler} settings={{ width: '109px' }} variant="accent" size="small">
      <div {...stylex.props(styles.childBlock)}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 5.1875V10.8124" stroke={colors.primaryInverseText} strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M5.18701 8H10.8119"
            stroke={colors.primaryInverseText}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.99987 15C11.8748 15 14.9998 11.8749 14.9998 8C14.9998 4.12507 11.8748 1 7.99987 1C4.12493 1 1 4.12507 1 8C1 11.8749 4.12493 15 7.99987 15Z"
            stroke={colors.primaryInverseText}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p {...stylex.props(styles.text)}>Создать</p>
      </div>
    </Button>
  );
};

export default CreateTab;
