import { useMutation } from 'urql';
import { gql } from 'urql';

export const LOGIN_MUTATION = gql`
  mutation ($email: String!, $password: String!) {
    signIn(input: { email: $email, password: $password })
  }
`;
export interface ILoginVariables {
  email: string;
  password: string;
}

export interface ILoginResponse {
  signIn: string;
}

export const useLogin = () => {
  return useMutation<ILoginResponse, ILoginVariables>(LOGIN_MUTATION);
};
