import { ConfirmDialog, IConfirmProps } from '@features/confirm-dialog';
import { FC, ReactNode, createContext, useState } from 'react';

interface IConfirmProviderProps {
  children: ReactNode;
}

export interface IConfirmProvider {
  openConfirmDialog: (
    message: string,
    header?: string,
    okBtnLabel?: string,
    cancelBtnLabel?: string
  ) => Promise<unknown>;
}

export const ConfirmContext = createContext<IConfirmProvider | undefined>(undefined);

export const ConfirmProvider: FC<IConfirmProviderProps> = ({ children }) => {
  const [confirmState, setConfirmState] = useState<IConfirmProps>({
    isOpen: false,
    header: 'Подтвердите действие',
    message: '',
    okBtnLabel: 'Подтвердить',
    cancelBtnLabel: 'Отмена',
    onConfirm: () => {},
    onCancel: () => {},
  });

  const openConfirmDialog = (
    message: string,
    header = 'Подтвердите действие',
    okBtnLabel = 'Подтвердить',
    cancelBtnLabel = 'Отмена'
  ) => {
    return new Promise((resolve) => {
      setConfirmState({
        isOpen: true,
        header,
        message,
        okBtnLabel,
        cancelBtnLabel,
        onConfirm: () => {
          resolve(true);
          setConfirmState({ ...confirmState, isOpen: false });
        },
        onCancel: () => {
          resolve(false);
          setConfirmState({ ...confirmState, isOpen: false });
        },
      });
    });
  };

  return (
    <ConfirmContext.Provider value={{ openConfirmDialog }}>
      {children}
      {confirmState.isOpen && (
        <ConfirmDialog
          isOpen={confirmState.isOpen}
          header={confirmState.header}
          message={confirmState.message}
          okBtnLabel={confirmState.okBtnLabel}
          cancelBtnLabel={confirmState.cancelBtnLabel}
          onConfirm={confirmState.onConfirm}
          onCancel={confirmState.onCancel}
        />
      )}
    </ConfirmContext.Provider>
  );
};
