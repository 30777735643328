import { FC } from 'react';
import { IconButton } from '../icon-button';
import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { ChevronIconLeftRounded } from '../icons/chevron-icon-left';
import { useNavigate } from 'react-router-dom';
import useImageValidator from '@pages/event/lib/useImageValidator';
import { EmptyImage } from '../empty';
interface IImageCardProps {
  image: string;
}

export const ImageCard: FC<IImageCardProps> = ({ image }) => {
  const navigate = useNavigate();
  const isValid = useImageValidator(image);

  // const [file, setFile] = useState('null');

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length) {
      // const file = event.target.files[0];
      // const fileUrl = URL.createObjectURL(file);
      // setFile(fileUrl);
    }
  }

  return (
    <div {...stylex.props(styles.base)}>
      <IconButton onClick={() => navigate(-1)} icon={<ChevronIconLeftRounded />} variant="primary">
        Назад
      </IconButton>
      <div {...stylex.props(styles.imageWrapper)}>{!isValid ? <EmptyImage /> : <img src={image} alt="" />}</div>
      {/* <button {...stylex.props(styles.editButton)}>
        <label {...stylex.props(styles.label)} htmlFor="file">
          Изменить
        </label>
      </button> */}
      <input
        id="file"
        onChange={handleChange}
        type="file"
        name="myImage"
        accept="image/png, image/gif, image/jpeg"
        hidden
      />
    </div>
  );
};
