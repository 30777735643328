import { FC } from 'react';

export const Bin: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00008 1C5.63498 1 5.27126 1.00584 4.90901 1.01743C4.40593 1.03352 4.00008 1.45596 4.00008 1.98546V2.06055C4.66166 2.02038 5.32852 2 6.00008 2C6.67164 2 7.33851 2.02038 8.00008 2.06055V1.98546C8.00008 1.45596 7.59423 1.03352 7.09115 1.01743C6.7289 1.00584 6.36518 1 6.00008 1ZM9.00008 2.13665V1.98546C9.00008 0.942476 8.19173 0.0521217 7.12313 0.0179381C6.75022 0.00600926 6.37585 0 6.00008 0C5.62432 0 5.24994 0.00600926 4.87704 0.017938C3.80844 0.0521217 3.00008 0.942476 3.00008 1.98546V2.13665C2.3636 2.19492 1.73239 2.27154 1.10699 2.36599C0.875431 2.40095 0.644674 2.43837 0.414741 2.4782C0.142651 2.52533 -0.0397137 2.78411 0.00741868 3.0562C0.054551 3.32829 0.313332 3.51066 0.585423 3.46352C0.631826 3.45549 0.678264 3.44755 0.724737 3.43971L1.39502 12.1534C1.47517 13.1954 2.34406 14 3.38913 14H8.61104C9.65611 14 10.525 13.1954 10.6051 12.1534L11.2754 3.43971C11.3219 3.44755 11.3683 3.45549 11.4147 3.46352C11.6868 3.51066 11.9456 3.32829 11.9927 3.0562C12.0399 2.78411 11.8575 2.52533 11.5854 2.4782C11.3555 2.43837 11.1247 2.40095 10.8932 2.36599C10.2678 2.27154 9.63657 2.19492 9.00008 2.13665ZM10.2841 3.28881C9.68185 3.20693 9.07418 3.14207 8.46156 3.09473C7.6494 3.03197 6.82853 3 6.00008 3C5.17164 3 4.35076 3.03197 3.5386 3.09473C2.92598 3.14207 2.31831 3.20693 1.71608 3.28881L2.39207 12.0767C2.43215 12.5977 2.86659 13 3.38913 13H8.61104C9.13357 13 9.56801 12.5977 9.60809 12.0767L10.2841 3.28881ZM4.15394 4.50037C4.42988 4.48976 4.66218 4.70485 4.67279 4.98078L4.90356 10.9808C4.91417 11.2567 4.69908 11.489 4.42314 11.4996C4.14721 11.5102 3.91491 11.2952 3.9043 11.0192L3.67353 5.01922C3.66292 4.74328 3.878 4.51098 4.15394 4.50037ZM7.84622 4.50037C8.12216 4.51098 8.33725 4.74328 8.32664 5.01922L8.09587 11.0192C8.08525 11.2952 7.85296 11.5102 7.57702 11.4996C7.30108 11.489 7.08599 11.2567 7.09661 10.9808L7.32737 4.98078C7.33799 4.70485 7.57028 4.48976 7.84622 4.50037Z"
        fill="#DD524C"
      />
    </svg>
  );
};
