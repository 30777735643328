import { ButtonProps } from '@sminex/sminex-uikit/dist/src/ui-components/button/model/types';
import * as stylex from '@stylexjs/stylex';
import React from 'react';
import ArrowsIcon from '../arrows-icon.tsx';
import { styles } from './styles.ts';

const ButtonWithArrows: React.FC<ButtonProps> = ({ onClick, children }, props) => {
  return (
    <button {...stylex.props(styles.button)} onClick={onClick} {...props}>
      {children}
      <ArrowsIcon />
    </button>
  );
};

export default ButtonWithArrows;
