import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  base: {
    position: 'relative',
    width: 'calc(100vw - 160px - 109px - 109px - 32px)',
  },
  searchIcon: {
    position: 'absolute',
    right: '33px',
    top: '10px',
    cursor: 'pointer',
  },
  crossIcon: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
  },
  card: {
    cursor: 'pointer',
    width: '302px',
  },
});
