import React, { useEffect } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface ToastProps {
  id: number;
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  onClose: (id: number) => void;
  index: number;
  duration: number;
}

const OneToast: React.FC<ToastProps> = ({ id, message, type, onClose, index, duration }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose(id);
    }, duration + 1000);
    return () => clearTimeout(timer);
  }, [id, onClose]);

  return <div {...stylex.props([styles.base(index, duration), styles[type]])}>{message}</div>;
};

export default OneToast;
