import React from 'react';
import { Toast } from './shared';
import OneToast from './one-toast';
import { styles } from './styles';
import * as stylex from '@stylexjs/stylex';
import { createPortal } from 'react-dom';

interface ToastsProps {
  toasts: Toast[];
  onClose: (id: number) => void;
}

const Toasts: React.FC<ToastsProps> = ({ toasts, onClose }) => {
  return createPortal(
    <div {...stylex.props(styles.container)}>
      {toasts.map((toast, index) => (
        <OneToast key={toast.id} onClose={onClose} index={index + 1} {...toast} />
      ))}
    </div>,
    document.getElementById('toast') as Element
  );
};

export default Toasts;
