import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';
import * as stylex from '@stylexjs/stylex';
import { typography } from '@shared/tokens/typography.stylex';

export const styles = stylex.create({
  inputFileContainer: (dragActive) => ({
    width: '320px',
    height: '156px',
    gap: '8px',
    borderRadius: '8px',
    border: '1px solid',
    background: colors.primaryBackground,
    opacity: dragActive ? '0.1' : 'none',
    borderColor: dragActive ? colors.primaryBorder : colors.tertiaryBorder,
    paddingTop: '32px',
    cursor: dragActive && 'all-scroll',
  }),
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-2'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightCaption-2'],
    textAlign: 'left',
    color: colors.tertiaryText,
  },
  buttonText: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-1'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightCaption-1'],
    textAlign: 'left',
  },
});
