import { RouterProvider } from 'react-router-dom';
import { Router as router } from './router';
import { UrQlProvider } from './urql-provider';
import { AuthProvider } from './auth-provider';
import { PreloaderCircular } from '@sminex/sminex-uikit';
import { Suspense } from 'react';
import { ConfirmProvider } from './confirm-provider';
import { ToastProvider } from './toast-provider';
function App() {
  return (
    <>
      <AuthProvider>
        <ToastProvider>
          <ConfirmProvider>
            <UrQlProvider>
              <Suspense fallback={<PreloaderCircular />}>
                <RouterProvider router={router}></RouterProvider>
              </Suspense>
            </UrQlProvider>
          </ConfirmProvider>
        </ToastProvider>
      </AuthProvider>
    </>
  );
}

export default App;
