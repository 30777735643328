import { CardDetails } from '@shared/types/types';
import { useCallback, useEffect, useState } from 'react';
import { gql, useQuery } from 'urql';
import useWindowSize from './useWindowSize';
import { useToast } from './useToast';

const EventsQuery = gql`
  query GetEvents($page: Int!, $pageSize: Int!, $searchEvents: String) {
    getEvents(page: $page, pageSize: $pageSize, searchEvents: $searchEvents) {
      count
      total
      page
      pageCount
      data {
        id
        name
        description
        confirmed
        active
        image
        dateStart
        dateEnd
        createdAt
        updatedAt
        tickets {
          tickets {
            id
            ticketUuid
            checked
            inside
            active
            registration
            createdAt
            updatedAt
          }
          activeCount
          checkedCount
          registration
          total
        }
      }
    }
  }
`;
const useFetchEventCards = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const { width } = useWindowSize();

  const pageSize = width < 1440 ? 6 : 8;

  const [{ data, fetching, error }, executeQuery] = useQuery({
    query: EventsQuery,
    variables: { page: currentPage, pageSize, searchEvents: searchValue },
  });

  const { showToast } = useToast();

  useEffect(() => {
    if (error) {
      showToast('Ошибка при получении данных', 'error');
    }
  }, [error]);

  const eventCards: CardDetails[] = data?.getEvents?.data;
  const pageCount: number = data?.getEvents?.pageCount;

  const handlePageChange = useCallback(
    (value: number) => {
      if (value === currentPage) return;
      setCurrentPage(value);
    },
    [currentPage]
  );

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };

  const handleNextPageClick = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPageClick = () => {
    setCurrentPage((prev) => prev - 1);
  };

  return {
    handleNextPageClick,
    handlePreviousPageClick,
    handlePageChange,
    eventCards,
    fetching,
    error,
    currentPage,
    pageCount,
    pageSize,
    executeQuery,
    handleSearchChange,
  };
};

export default useFetchEventCards;
