import { gql, useQuery } from 'urql';
export const GET_QR_QUERY = gql`
  query ($id: Int!) {
    getTicketQrcode(input: { id: $id, qrCodeSize: 256 })
  }
`;

export interface IQrResponse {
  getTicketQrcode: string;
}

export const useFetchTicketQr = (id: number) => {
  const [result, reexecuteQuery] = useQuery<IQrResponse>({
    query: GET_QR_QUERY,
    variables: { id },
    pause: !id,
  });
  return { ...result, reexecuteQuery };
};
