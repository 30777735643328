import { colors } from '@shared/tokens/colors.stylex';
import { fonts } from '../../shared/tokens/fonts.stylex';
import { typography } from '../../shared/tokens/typography.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  childBlock: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  text: {
    fontFamily: fonts.din2014Regular,
    fontSize: '14px',
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['fontSizeBody-2'],
    color: colors.primaryInverseText,
  },
});
