import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import { SminexLogo } from '@shared/ui/sminex-logo';
import { colors } from '@shared/tokens/colors.stylex';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

export const BasicLayout: FC = () => {
  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.logo)}>
        <SminexLogo colorPrimary={colors.primaryInverseBackground} colorSecodary={colors.accentBackground} />
      </div>
      <Outlet />
    </div>
  );
};
