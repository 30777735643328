import stylex from '@stylexjs/stylex';
export const styles = stylex.create({
  base: {
    // background: '#e0e0e0',
    // borderRadius: '9999px',
    // fontSize: '12px',
    // height: '24px',
    // alignItems: 'center',
    // cursor: 'default',
    // display: 'inline-flex',
    // lineHeight: '20px',
    // maxWidth: '100%',
    // outline: 'none',
    // overflow: 'hidden',
    // padding: '0 10px',
    // position: 'relative',
    // textDecoration: 'none',
    // transitionDuration: '.28s',
    // transitionProperty: 'box-shadow, opacity',
    // transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
    // verticalAlign: 'middle',
    // whiteSpace: 'nowrap',
  },
});
