import { colors } from '@shared/tokens/colors.stylex';

const ExcelIcon = ({ fillColor = colors.primaryIcon }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8039 3.23921C11.4227 3.11545 12 3.58875 12 4.21979V19.7802C12 20.4112 11.4227 20.8845 10.8039 20.7608L2.80388 19.1608C2.33646 19.0673 2 18.6569 2 18.1802V5.81979C2 5.34311 2.33646 4.9327 2.80388 4.83921L10.8039 3.23921ZM5.95312 8.29686H4.17578L6.06055 11.9961L4 15.7031H5.73633L6.85938 13.3086C6.93437 13.0796 6.98477 12.9089 7.00977 12.7949H7.02539C7.06639 13.0329 7.1162 13.204 7.1582 13.289L8.27148 15.7031H10L8.00781 11.9668L9.94336 8.29686H8.32227L7.29297 10.4941C7.19197 10.7791 7.1248 10.9976 7.0918 11.1406H7.06641C7.00841 10.9026 6.95195 10.6927 6.87695 10.5117L5.95312 8.29686Z"
        fill={fillColor}
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 5.99999C13.75 5.58577 14.0858 5.24999 14.5 5.24999H20C20.9665 5.24999 21.75 6.03349 21.75 6.99999V17C21.75 17.9665 20.9665 18.75 20 18.75H14.5C14.0858 18.75 13.75 18.4142 13.75 18C13.75 17.5858 14.0858 17.25 14.5 17.25H16.25V14.75H14.5C14.0858 14.75 13.75 14.4142 13.75 14C13.75 13.5858 14.0858 13.25 14.5 13.25H16.25V10.75H14.5C14.0858 10.75 13.75 10.4142 13.75 9.99999C13.75 9.58577 14.0858 9.24999 14.5 9.24999H16.25V6.74999H14.5C14.0858 6.74999 13.75 6.4142 13.75 5.99999ZM20 17.25H17.75V14.75H20.25V17C20.25 17.1381 20.1381 17.25 20 17.25ZM20.25 13.25H17.75V10.75H20.25V13.25ZM20.25 9.24999H17.75V6.74999H20C20.1381 6.74999 20.25 6.86192 20.25 6.99999V9.24999Z"
        fill={fillColor}
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default ExcelIcon;
