import { colors } from '@shared/tokens/colors.stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
import { typography } from '@shared/tokens/typography.stylex';
import stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  buttonsContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '16px', paddingBottom: '16px' },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '4px',
  },
  accessText: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-2'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: '12px',
    color: colors.secondaryText,
    gap: '4px',
    padding: '12px 16px',
  },
  qrButtonsPanel: { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '16px' },
  menuItem: {
    color: colors.primaryText,
    fontSize: '14px',
  },
  segment: {
    padding: '10px 0 ',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '20px',
  },
  label: {
    paddingRight: '10px',
  },
});
