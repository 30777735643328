import useFetchEventCards from '@shared/hooks/useFetchEventCards';
import * as stylex from '@stylexjs/stylex';
import React, { useEffect } from 'react';
import EventCardSwitcher from '@features/event-card-switcher';
import EventCardsSort from '@features/event-cards-sort';
import { styles } from './styles';
import { useToast } from '@shared/hooks/useToast';

export const EventsMain: React.FC = () => {
  const { eventCards, pageSize, error, fetching } = useFetchEventCards();

  const { showToast } = useToast();

  useEffect(() => {
    if (error) {
      showToast('Ошибка при получении данных', 'error');
    }
  }, [error]);

  return (
    <>
      <div {...stylex.props(styles.container)} data-testid="events-main-container">
        <div {...stylex.props(styles.header)}>
          <div {...stylex.props(styles.title)}>Мероприятия</div>
          <EventCardsSort></EventCardsSort>
        </div>

        <div {...stylex.props(styles.cards)} data-testid="events-main-cards">
          {fetching ? (
            new Array(pageSize).fill(null).map((_, index) => <EventCardSwitcher key={index} />)
          ) : eventCards?.length > 0 ? (
            eventCards.map((card, index) => <EventCardSwitcher key={index} cardDetails={card} />)
          ) : (
            <div {...stylex.props(styles.content)}>Список мероприятий пуст</div>
          )}
        </div>
        {/* 
         Пагинация отключена, до тех пор пока на беке не сделают
       {(!fetching || !eventCards?.length) && (
         <div {...stylex.props(styles.pagination)}>
           <Pagination
             totalPage={pageCount || 0}
            changeFunction={handlePageChange}
             onNextPageClick={handleNextPageClick}
             onPreviousPageClick={handlePreviousPageClick}
            currentPage={currentPage}
            isActive={true}
          />
        </div>
     )} */}
      </div>
    </>
    // <>
  );
};
