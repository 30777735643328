import { colors } from '@shared/tokens/colors.stylex';

const SminexText = () => {
  return (
    <svg width="68" height="16" viewBox="0 0 68 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M57.0571 15.4833L61.2707 10.4648L57.2118 4.69933H59.4152C60.4976 4.69933 60.3429 5.13989 62.2178 7.80236L62.8556 8.72178L65.407 4.83342C65.4843 4.71849 65.6196 4.68018 65.7355 4.68018H67.0499L63.2229 9.23895L67.649 15.4833H65.5809C64.2086 15.5025 64.4599 14.947 62.643 12.3994L61.6379 10.982L58.7194 15.3109C58.642 15.4259 58.5261 15.4642 58.3715 15.4642H57.0571V15.4833Z"
        fill={colors.primaryText}
      />
      <path
        d="M52.631 15.694C51.1427 15.694 49.5964 15.2726 48.63 14.085C47.7216 12.9741 47.393 11.5949 47.3544 10.1967C47.335 8.4536 47.7796 7.05532 48.7073 6.00182C49.6351 4.94833 50.9108 4.43115 52.5343 4.43115C53.3075 4.43115 54.0033 4.54608 54.6025 4.77593C55.2016 5.00579 55.6655 5.31226 56.0328 5.71451C56.4 6.11675 56.6706 6.57646 56.8446 7.09363C57.0185 7.6108 57.1152 8.18543 57.1345 8.79838C57.1345 9.124 57.1152 9.43048 57.0572 9.69864C57.0572 9.73695 57.0185 9.7561 56.9992 9.7561H50.1956C50.2343 11.4992 50.5049 12.7442 51.0074 13.5295C51.51 14.3149 52.3411 14.698 53.5201 14.698C54.7378 14.698 55.6849 14.3532 56.6126 13.6445C56.7672 13.7786 56.9412 14.1425 56.6126 14.4298C55.7622 15.196 54.0999 15.694 52.631 15.694ZM50.1763 9.23893L54.5831 8.91331C54.6025 7.45756 54.5445 5.06325 52.5923 5.06325C50.5435 5.06325 50.2343 7.68742 50.1763 9.23893Z"
        fill={colors.primaryText}
      />
      <path
        d="M45.6534 14.2384V7.91741C45.6534 5.75295 44.8222 4.45044 42.6768 4.45044C41.1692 4.45044 39.7389 5.15915 38.7145 6.21265C38.7145 5.36985 38.5792 4.68029 37.7481 4.68029H36.0085L36.1052 5.90618V14.2192L36.0085 15.4643H38.8305L38.7338 14.2192V6.78729C39.5456 6.17434 40.2994 5.86787 41.3818 5.86787C42.7541 5.86787 43.0247 7.13207 43.0247 8.53035V14.0085C43.0247 14.928 43.2373 15.4643 44.0105 15.4643H45.75L45.6534 14.2384Z"
        fill={colors.primaryText}
      />
      <path
        d="M33.2834 1.55808C33.2834 2.32426 32.6455 2.93721 31.8724 2.93721C31.0993 2.93721 30.4614 2.32426 30.4614 1.55808C30.4614 0.791902 31.0993 0.178955 31.8724 0.178955C32.6455 0.178955 33.2834 0.791902 33.2834 1.55808Z"
        fill={colors.primaryText}
      />
      <path
        d="M30.5581 4.68004L30.6547 5.90593V14.219L30.5581 15.464H33.38L33.2834 14.219V6.11663C33.2834 5.29298 33.1288 4.66089 32.2977 4.66089H30.5581V4.68004Z"
        fill={colors.primaryText}
      />
      <path
        d="M27.8908 14.2384V7.91741C27.8908 5.73379 27.0597 4.45044 24.9143 4.45044C23.1167 4.45044 21.8991 5.3507 20.9327 6.36589C20.5847 5.15916 19.7343 4.45044 18.1107 4.45044C16.5838 4.45044 15.3468 5.15915 14.3417 6.23181V6.21265C14.3417 5.36985 14.2064 4.68029 13.3753 4.68029H11.6357L11.7324 5.90618V14.2192L11.6357 15.4643H14.4577L14.361 14.2192V6.78729C15.1535 6.13603 16.1006 5.86787 16.8544 5.86787C18.3427 5.86787 18.4973 7.38108 18.4973 8.53035V14.2192L18.4006 15.4643H21.2226L21.1259 14.2192V6.78729C21.8024 6.21265 22.7495 5.86787 23.6386 5.86787C25.1849 5.86787 25.2815 7.53431 25.2815 8.53035V14.0085C25.2815 15.0429 25.5714 15.4643 26.2673 15.4643H28.0068L27.8908 14.2384Z"
        fill={colors.primaryText}
      />
      <path
        d="M4.50353 15.6941C3.20853 15.6941 1.29503 15.3302 0.135336 14.6789C0.347947 14.2001 0.753841 13.8361 1.33369 14.1426C2.37742 14.6789 3.42114 14.9279 4.58084 14.9279C6.24307 14.9279 7.46076 14.0085 7.38344 12.3421C7.20949 8.85595 -0.0192896 8.33878 3.86905e-05 4.14394C0.019367 1.06006 2.82197 0.178955 5.5086 0.178955C6.8036 0.178955 8.07926 0.427968 9.3356 0.925986C9.12299 1.424 8.75575 1.8454 8.11792 1.53893C7.22881 1.11753 6.33972 0.925986 5.39263 0.925986C3.74973 0.925986 2.82197 1.80709 2.82197 3.1096C2.80264 6.44248 10.1474 6.9405 10.2827 11.2694C10.36 14.4299 7.6927 15.6941 4.50353 15.6941Z"
        fill={colors.primaryText}
      />
    </svg>
  );
};

export default SminexText;
