import { string, ValidationError } from 'yup';

export type IValidateValue = string | false;

const MIN_PHRASE_LENGTH = 4;
const MAX_PHONE_LENGTH = 11;
export const EMAIL_ERROR_MESSAGE = 'Неверный адрес электронной почты';
export const PHRASE_ERROR_MESSAGE = `Поле должно содержать не менее ${MIN_PHRASE_LENGTH} знаков`;
export const REQURED_MAIL_MESSAGE = 'Поле «Почта» должно быть заполнено';
export const REQURED_PHRASE_MESSAGE = 'Поле должно быть заполнено';

export const REQURED_LAT_MESSAGE = 'Поле содержит не допустимые символы';
export const REQURED_PHONE_MESSAGE = 'Номер телефона содержит не допустимые символы';

export const REGEX_LAT_SCHEMA = /^[0-9ёЁа-яА-Я-']+$/;
const REGEX_PHONE_SCHEMA =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const emailValidator = async (eamil: string) => {
  let resp: IValidateValue = false;
  const eamilSchema = string().default('').email(EMAIL_ERROR_MESSAGE).required(REQURED_MAIL_MESSAGE);
  try {
    await eamilSchema.validate(eamil);
  } catch (e) {
    if (e instanceof ValidationError) {
      resp = e.message;
    }
  }
  return resp;
};

export const phraseLatValidator = async (phrase: string) => {
  let resp: IValidateValue = false;
  const phraseSchema = string()
    .default('')
    .matches(REGEX_LAT_SCHEMA, REQURED_LAT_MESSAGE)
    .required(REQURED_PHRASE_MESSAGE)
    .min(MIN_PHRASE_LENGTH, PHRASE_ERROR_MESSAGE);
  try {
    await phraseSchema.validate(phrase);
  } catch (e) {
    if (e instanceof ValidationError) {
      resp = e.message;
    }
  }
  return resp;
};

export const phraseValidator = async (phrase: string) => {
  let resp: IValidateValue = false;
  const phraseSchema = string()
    .default('')
    .required(REQURED_PHRASE_MESSAGE)
    .min(MIN_PHRASE_LENGTH, PHRASE_ERROR_MESSAGE);
  try {
    await phraseSchema.validate(phrase);
  } catch (e) {
    if (e instanceof ValidationError) {
      resp = e.message;
    }
  }
  return resp;
};

export const phoneValidator = async (phrase: string) => {
  let resp: IValidateValue = false;
  const phraseSchema = string()
    .default('')
    .matches(REGEX_PHONE_SCHEMA, REQURED_PHONE_MESSAGE)
    .required(REQURED_PHRASE_MESSAGE)
    .min(MIN_PHRASE_LENGTH, PHRASE_ERROR_MESSAGE)
    .max(MAX_PHONE_LENGTH, PHRASE_ERROR_MESSAGE);
  try {
    await phraseSchema.validate(phrase);
  } catch (e) {
    if (e instanceof ValidationError) {
      resp = e.message;
    }
  }
  return resp;
};
