import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  base: {
    position: 'relative',
    width: {
      default: '1160px',
      '@media (max-width: 1439px)': '834px',
    },
    boxSizing: 'border-box',
    ':not(#__unused__) > div': {
      width: '100%',
    },
  },
  searchIcon: {
    position: 'absolute',
    right: '33px',
    top: '10px',
    cursor: 'pointer',
  },
  crossIcon: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
  },
});
