import stylex from '@stylexjs/stylex';
import { FC, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useFetchEvent } from './api/useFetchEvent';
import { styles } from './styles';
import { EventBody } from './ui/event-body';
import { ImageCard } from '@shared/ui/image-card';
import { useToast } from '@shared/hooks/useToast';
export const EventPage: FC = () => {
  const navigation = useNavigate();
  const { id } = useParams();

  const { event, error } = useFetchEvent(Number(id));
  const { showToast } = useToast();

  useEffect(() => {
    if (error) {
      showToast('Ошибка при получении данных', 'error');
    }
  }, [error]);

  error && navigation('/notfound');
  event === null && navigation('/notfound');

  return (
    <div {...stylex.props(styles.container)}>
      <ImageCard image={''} />
      <EventBody event={event} />
    </div>
  );
};
