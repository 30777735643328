import { useMutation } from 'urql';
import { gql } from 'urql';

export const ADD_SEGMENT_MUTATION = gql`
  mutation ($title: [String!], $eventId: [Int!], $description: [String!]) {
    createTicketSegment(input: { title: $title, eventId: $eventId, description: $description }) {
      id
      title
      description
    }
  }
`;

export interface IAddSegmentVariables {
  title: string;
  eventId: number;
  description: string;
}

export interface IAddSegmentResponse {
  createTicketSegment: {
    id: number;
    title: string;
    description: string;
  };
}

export const useFetchAddSegment = () => {
  return useMutation<IAddSegmentResponse, IAddSegmentVariables>(ADD_SEGMENT_MUTATION);
};
