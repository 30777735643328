import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import EventForm from '../../features/create-event-form';
import { Stepper } from '@sminex/sminex-uikit';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export interface IStepperProps {
  title?: string;
  isCompleted?: boolean;
}

const baseSteps: IStepperProps[] = [
  { title: 'Основная информация', isCompleted: true },
  { title: 'Участники', isCompleted: false },
];

export const NewEvent: FC = () => {
  const navigate = useNavigate();
  const [steps, setSteps] = useState<IStepperProps[]>(baseSteps);
  const [currentStep, setCurrentStep] = useState<number>(0);

  const nextStepHandle = () => {
    setSteps((previousSteps) =>
      previousSteps.map((step, index) => ({
        ...step,
        isCompleted: index === currentStep + 1 ? true : step.isCompleted,
      }))
    );
    setCurrentStep(currentStep < steps.length - 1 ? currentStep + 1 : currentStep);
  };

  const prevStepHandle = () => {
    const previousStep = currentStep - 1;
    setCurrentStep(previousStep < 0 ? 0 : previousStep);
    if (previousStep < 0) {
      navigate(-1);
      return;
    }

    setSteps((stepsPrev) =>
      stepsPrev.map((step, index) => ({
        ...step,
        isCompleted: index === currentStep ? false : step.isCompleted,
      }))
    );
  };

  return (
    <div {...stylex.props(styles.base)}>
      <div {...stylex.props(styles.stepper)}>
        <Stepper steps={steps} changeFunction={() => {}} />
      </div>
      <EventForm
        currentStep={currentStep}
        nextStepHandle={nextStepHandle}
        prevStepHandle={prevStepHandle}
        selectedDate={[]}
      />
    </div>
  );
};
