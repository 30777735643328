import { Button } from '@sminex/sminex-uikit';
import { ButtonProps } from '@sminex/sminex-uikit/dist/src/ui-components/button/model/types';
import { FC } from 'react';

import stylex from '@stylexjs/stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';

const styles = stylex.create({
  button: {
    fontFamily: fonts.din2014Regular,
    color: colors.primaryBackground,
    fontSize: '18px',
  },
});
export const BasicButton: FC<ButtonProps> = ({ children, onClick, settings }, props) => {
  return (
    <Button onClick={onClick} settings={{ ...settings, padding: '0 24px 0 24px' }} {...props}>
      <div {...stylex.props(styles.button)}>{children}</div>
    </Button>
  );
};
