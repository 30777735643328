import React from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles';

interface EmployeeSwitcherProps {
  blocks: string[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}

const EmployeeSwitcher: React.FC<EmployeeSwitcherProps> = ({ blocks, activeIndex, setActiveIndex }) => {
  const handleSwitch = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <div {...stylex.props(styles.switcher)}>
      {blocks?.map((item, index) => (
        <div
          key={index}
          {...stylex.props(styles.switcherBlock, activeIndex === index && styles.active)}
          onClick={() => handleSwitch(index)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default EmployeeSwitcher;
