import { useMutation } from 'urql';
import { gql } from 'urql';

export const ADD_USER_TO_GROUP_MUTATION = gql`
  mutation ($userId: [Int!], $groupId: [Int!]) {
    addUserToGroup(input: { userId: $userId, groupId: $groupId, roleId: 0 }) {
      name
      createdAt
      updatedAt
      id
      users {
        id
        uuid
        email
        login
        position
        phone
        firstName
        lastName
        avatar
        confirmed
        departamentUuid
        createdAt
        updatedAt
      }
    }
  }
`;

export interface IAddUserToGroupVariables {
  userId: number;
  groupId: number;
}

export interface IAddUserToGroupResponse {
  addUserToGroup: {
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
  };
}

export const useFetchAddOrganizer = () => {
  return useMutation<IAddUserToGroupResponse, IAddUserToGroupVariables>(ADD_USER_TO_GROUP_MUTATION);
};
