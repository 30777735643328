import stylex from '@stylexjs/stylex';

import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: fonts.din2014Regular,
    minHeight: '200px',
    padding: '0px',
  },

  header: {
    padding: '16px 24px',
    color: colors.primaryText,
    fontSize: '24px',
    fontFamily: fonts.din2014Regular,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '8px',
    padding: ' 10px 24px 16px ',
  },
  loader: {
    margin: '0 auto',
  },
  inputWrapper: {
    padding: ' 0px 24px ',
  },

  switcherLabel: {
    fontSize: '14px',
    paddingLeft: '8px',
    color: colors.primaryText,
  },
  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },

  input: {
    paddingBottom: '14px',
  },
});
