import Toasts from '@shared/ui/toasts';
import React, { createContext, useState, ReactNode, useRef, useEffect } from 'react';

interface ToastProps {
  id: number;
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
  duration: number;
}

export interface ToastContextProps {
  showToast: (message: string, type: 'success' | 'error' | 'warning' | 'info', duration?: number) => void;
}

export const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const showToast = (message: string, type: 'success' | 'error' | 'warning' | 'info', duration = 3000): void => {
    const id = Date.now();
    const newToast: ToastProps = {
      id,
      message,
      type,
      duration,
    };
    setToasts((prev) => [newToast, ...prev]);

    timerRef.current = setTimeout(() => {
      setToasts((prev) => prev.filter((toast) => toast.id !== id));
    }, duration + 1000);
  };

  const onClose = (id: number) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toasts onClose={onClose} toasts={toasts} />
    </ToastContext.Provider>
  );
};
