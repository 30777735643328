import { colors } from '@shared/tokens/colors.stylex';
import { FC } from 'react';

export const DownloadIcon: FC = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7.5C14.2761 7.5 14.5 7.72386 14.5 8V15.7929L16.6464 13.6464C16.8417 13.4512 17.1583 13.4512 17.3536 13.6464C17.5488 13.8417 17.5488 14.1583 17.3536 14.3536L14.3536 17.3536C14.1583 17.5488 13.8417 17.5488 13.6464 17.3536L10.6464 14.3536C10.4512 14.1583 10.4512 13.8417 10.6464 13.6464C10.8417 13.4512 11.1583 13.4512 11.3536 13.6464L13.5 15.7929V8C13.5 7.72386 13.7239 7.5 14 7.5ZM8 16.5C8.27614 16.5 8.5 16.7239 8.5 17V18.5C8.5 19.0523 8.94772 19.5 9.5 19.5H18.5C19.0523 19.5 19.5 19.0523 19.5 18.5V17C19.5 16.7239 19.7239 16.5 20 16.5C20.2761 16.5 20.5 16.7239 20.5 17V18.5C20.5 19.6046 19.6046 20.5 18.5 20.5H9.5C8.39543 20.5 7.5 19.6046 7.5 18.5V17C7.5 16.7239 7.72386 16.5 8 16.5Z"
        fill={colors.primaryText}
      />
    </svg>
  );
};
