import { Button } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import React from 'react';
import PlusIcon from '@shared/ui/plus-icon.tsx';
import { colors } from '@shared/tokens/colors.stylex.ts';

interface AddSegmentProps {
  text?: string;
  onClick: () => void;
  width?: string;
}

const AddSegment: React.FC<AddSegmentProps> = ({ onClick, width, text = 'Добавить сегмент' }) => {
  const clickHandler = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    onClick();
  };

  return (
    <Button size="small" variant="primary" onClick={clickHandler} settings={{ width }}>
      <p {...stylex.props(styles.buttonText)}>
        <PlusIcon fillColor={colors.inverseIcon} /> {text}
      </p>
    </Button>
  );
};

export default AddSegment;
