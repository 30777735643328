import { Button } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import ExcelIcon from './lib/ui/excel-icon.tsx';
import ButtonWithPlus from '@shared/ui/button-with-plus/index.tsx';
import useUploadFileDnd from '@shared/hooks/useUploadFileDnd.ts';
import { useRef } from 'react';

interface WithExcelProps {
  onFileChange: (file: File) => void;
}

const WithExcel: React.FC<WithExcelProps> = ({ onFileChange }) => {
  const { handleDragLeave, handleDragOver, setDragActive } = useUploadFileDnd();

  const excelInputRef = useRef<HTMLInputElement>(null);

  const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    excelInputRef?.current?.click();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);

    const file = event.dataTransfer.files?.length && event.dataTransfer.files[0];

    if (file) {
      const { size } = file;
      const maxSize = 1.5 * 1024 * 1024;
      if (size > maxSize) return;
      if (excelInputRef.current) {
        excelInputRef.current.files = event.dataTransfer.files;
      }
      onFileChange(file);
      // const fileUrl = URL.createObjectURL(file);
      // setPreviewUrl(fileUrl);
      event.dataTransfer.clearData();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onFileChange && event.target.files?.length) {
      const file = event.target.files[0];
      onFileChange(file);
      // const fileUrl = URL.createObjectURL(file);
      // setPreviewUrl(fileUrl);
    }
  };

  return (
    <div {...stylex.props(styles.withExcelBase)}>
      <div {...stylex.props(styles.steps)}>
        <p {...stylex.props(styles.stepsText)}>Шаг 1 *</p>
        <Button variant="secondary" size="small" settings={{ width: '164px' }}>
          <p {...stylex.props(styles.textBtn)}>Скачать шаблон.xlsx</p>
        </Button>
      </div>
      <div {...stylex.props(styles.steps)}>
        <p {...stylex.props(styles.stepsText)}>Шаг 2 *</p>
        <div
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          {...stylex.props(styles.excelInput())}
        >
          <ExcelIcon />
          <div {...stylex.props(styles.excelText)}>
            Импортируйте файл Excel с предварительно заполненными участниками
          </div>
          <input type="file" ref={excelInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
          <ButtonWithPlus labelText="Импортировать" handleClick={handleClick} />
        </div>
      </div>
    </div>
  );
};

export default WithExcel;
