import { colors } from '@shared/tokens/colors.stylex';
import stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    width: 'auto',
    padding: '24px',
    borderRadius: '8px',
    border: `1px solid ${colors.ghostBorder}`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    fontSize: '16px',
    fontWeight: '400',
    color: colors.ghostText,
  },
});
