import { FC } from 'react';

export const SminexIllustration: FC = () => {
  return (
    <svg width="262" height="378" viewBox="0 0 262 378" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.12"
        d="M200.284 213.888C187.08 206.277 167.407 190.643 155.656 175.984C157.875 171.417 162.047 169.621 167.988 176.669C178.188 188.825 189.594 198.614 201.155 205.135C218.187 214.953 230.572 211.011 229.727 190.521C227.984 147.594 153.966 98.563 154.178 48.329C154.363 11.1407 183.04 17.1383 210.45 32.9392C223.863 40.7234 237.135 51.478 249.505 64.5866C247.314 69.4121 243.59 72.2586 237.041 64.8606C228.379 55.0063 218.869 47.0047 209.235 41.4637C192.52 31.828 183.014 36.9122 182.829 52.5456C182.855 92.5958 257.982 141.871 259.302 194.906C260.226 233.19 232.896 232.688 200.284 213.888Z"
        fill="url(#paint0_linear_647_3461)"
      />
      <path
        opacity="0.66"
        d="M180.063 223.984C166.86 216.372 147.187 200.739 135.436 186.08C137.654 181.513 141.826 179.717 147.768 186.765C157.968 198.92 169.374 208.71 180.934 215.231C197.967 225.049 210.351 221.107 209.506 200.617C207.763 157.69 133.746 108.659 133.957 58.4247C134.142 21.2364 162.82 27.234 190.23 43.0349C203.642 50.8191 216.914 61.5737 229.285 74.6823C227.093 79.5078 223.37 82.3544 216.821 74.9563C208.158 65.102 198.649 57.1004 189.015 51.5594C172.3 41.9237 162.793 47.0079 162.608 62.6413C162.635 102.691 237.761 151.966 239.082 205.001C240.006 243.286 212.675 242.783 180.063 223.984Z"
        fill="url(#paint1_linear_647_3461)"
      />
      <path
        opacity="0.12"
        d="M153.547 202.763L213.751 237.461V366.904L153.547 332.238V202.763Z"
        fill="url(#paint2_linear_647_3461)"
      />
      <path
        opacity="0.3"
        d="M203.712 246.136L143.476 211.438V340.881L203.68 375.579L203.712 246.136ZM163.104 231.035L183.215 242.602V247.742L163.104 236.337V231.035Z"
        fill="url(#paint3_linear_647_3461)"
      />
      <path
        opacity="0.15"
        d="M149.3 296.735C149.3 295.279 150.876 294.368 152.138 295.095L196.714 320.769C197.301 321.107 197.663 321.732 197.663 322.409C197.663 323.866 196.087 324.777 194.825 324.05L150.249 298.376C149.662 298.038 149.3 297.412 149.3 296.735Z"
        fill="#78828A"
      />
      <path
        opacity="0.15"
        d="M152.711 307.209C152.711 305.752 154.287 304.841 155.549 305.568L193.368 327.355C193.955 327.693 194.316 328.318 194.316 328.995C194.316 330.452 192.74 331.362 191.478 330.635L153.659 308.849C153.073 308.511 152.711 307.886 152.711 307.209Z"
        fill="#78828A"
      />
      <path
        d="M187.045 282.568C187.045 272.32 181.027 260.529 173.627 256.256C166.226 251.983 160.209 256.834 160.209 267.083C160.209 277.331 166.226 289.122 173.627 293.395C181.027 297.668 187.045 292.817 187.045 282.568Z"
        fill="#1D252D"
        fillOpacity="0.15"
      />
      <path
        opacity="0.12"
        d="M78.5747 98.5723L138.778 133.27V262.713L78.5747 228.015V98.5723Z"
        fill="url(#paint4_linear_647_3461)"
      />
      <path
        opacity="0.3"
        d="M128.738 142.042L68.5024 107.344V236.787L128.706 271.485L128.738 142.042ZM88.1306 126.942L108.241 138.508V143.648L88.1306 132.243V126.942Z"
        fill="url(#paint5_linear_647_3461)"
      />
      <path
        opacity="0.15"
        d="M74.3271 192.512C74.3271 191.056 75.9031 190.145 77.1651 190.872L121.741 216.546C122.328 216.884 122.69 217.509 122.69 218.186C122.69 219.643 121.114 220.554 119.852 219.827L75.2754 194.153C74.6887 193.815 74.3271 193.189 74.3271 192.512Z"
        fill="#78828A"
      />
      <path
        opacity="0.15"
        d="M77.6822 203.005C77.693 201.548 79.2703 200.645 80.532 201.372L118.332 223.164C118.918 223.502 119.279 224.128 119.279 224.804C119.279 226.261 117.703 227.172 116.441 226.445L78.634 204.666C78.0408 204.324 77.6772 203.689 77.6822 203.005Z"
        fill="#78828A"
      />
      <path
        d="M112.071 178.378C112.071 168.129 106.054 156.338 98.6535 152.065C91.2528 147.792 85.2356 152.643 85.2356 162.892C85.2356 173.141 91.2528 184.932 98.6535 189.205C106.054 193.478 112.071 188.627 112.071 178.378Z"
        fill="#1D252D"
        fillOpacity="0.15"
      />
      <path
        opacity="0.3"
        d="M112.071 250.57L16.3115 195.374L16.3437 245.204L103.641 295.517L112.103 308.014L112.071 250.57Z"
        fill="url(#paint6_linear_647_3461)"
      />
      <path d="M6.11108 214.811V201.285L42.2462 222.104V235.63L6.11108 214.811Z" fill="#1D252D" fillOpacity="0.15" />
      <path
        opacity="0.15"
        d="M87.4552 249.608C87.4552 248.931 87.0935 248.305 86.5066 247.968L69.3136 238.068C68.0514 237.341 66.4756 238.252 66.4756 239.708C66.4756 240.386 66.8373 241.011 67.4241 241.349L84.6172 251.249C85.8794 251.976 87.4552 251.065 87.4552 249.608Z"
        fill="#78828A"
      />
      <path
        opacity="0.15"
        d="M87.4556 258.378C87.4556 257.701 87.094 257.076 86.5072 256.738L49.8468 235.626C48.5847 234.899 47.0088 235.81 47.0088 237.266C47.0088 237.943 47.3704 238.569 47.9572 238.907L84.6176 260.019C85.8798 260.746 87.4556 259.835 87.4556 258.378Z"
        fill="#78828A"
      />
      <path
        opacity="0.15"
        d="M87.4635 267.103C87.4585 266.434 87.0996 265.818 86.5202 265.484L41.3195 239.42C40.0578 238.692 38.4817 239.603 38.4817 241.059C38.4817 241.736 38.843 242.361 39.4292 242.699L84.6373 268.75C85.8994 269.478 87.4742 268.56 87.4635 267.103Z"
        fill="#78828A"
      />
      <path
        d="M92.6363 256.064C92.6363 260.176 95.0818 264.899 98.0743 266.634C101.067 268.369 103.512 266.441 103.512 262.329C103.512 258.216 101.067 253.494 98.0743 251.759C95.0496 250.024 92.6041 251.951 92.6363 256.064Z"
        fill="#C6C9CC"
      />
      <path
        opacity="0.66"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.80136 110.907L18.3199 118.693V134.285L4.80136 126.498V110.907ZM0.5 128.982V103.468L22.6213 116.21V141.724L0.5 128.982ZM18.3199 149.157L4.80136 141.37V156.961L18.3199 164.748V149.157ZM0.5 133.931V159.445L22.6213 172.187V146.673L0.5 133.931ZM9.51253 149.28L13.8139 151.758V156.719L9.51253 154.241V149.28ZM44.7426 133.925L31.2241 126.138V141.73L44.7426 149.517V133.925ZM26.9227 118.699V144.213L49.044 156.956V131.442L26.9227 118.699ZM13.8139 121.295L9.51253 118.817V123.778L13.8139 126.256V121.295ZM35.9352 134.049L40.2366 136.527V141.488L35.9352 139.01V134.049ZM31.2241 151.642L26.9227 149.163V174.667L31.2241 177.146V151.642ZM31.2245 167.228L35.5259 169.707V174.667L31.2245 172.187V167.228ZM35.5259 154.122L31.2245 151.642V156.601V156.601V161.56L48.43 171.478V166.519L48.4291 166.519V161.56L44.1278 159.08V164.039L35.5259 159.081V154.122ZM44.1278 179.626L48.4291 182.105V187.064L44.1278 184.585V179.626ZM48.4291 171.479L44.1282 168.999V168.999L39.8268 166.519V171.479L44.1278 173.958V173.958L48.4291 176.438V171.479ZM39.8268 177.146L35.5255 174.667V179.626L39.8268 182.105V177.146Z"
        fill="url(#paint7_linear_647_3461)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_647_3461"
          x1="276.512"
          y1="60.0594"
          x2="95.5444"
          y2="222.588"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBBEC0" />
          <stop offset="1" stopColor="#BBBEC0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_647_3461"
          x1="274.491"
          y1="25.7379"
          x2="132.472"
          y2="210.238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBBEC0" />
          <stop offset="0.929667" stopColor="#BBBEC0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_647_3461"
          x1="218.617"
          y1="224.381"
          x2="144.228"
          y2="353.459"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBBEC0" />
          <stop offset="1" stopColor="#BBBEC0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_647_3461"
          x1="208.547"
          y1="233.04"
          x2="134.158"
          y2="362.117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBBEC0" />
          <stop offset="1" stopColor="#BBBEC0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_647_3461"
          x1="143.642"
          y1="120.185"
          x2="69.253"
          y2="249.262"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBBEC0" />
          <stop offset="1" stopColor="#BBBEC0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_647_3461"
          x1="133.573"
          y1="128.946"
          x2="59.1843"
          y2="258.024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBBEC0" />
          <stop offset="1" stopColor="#BBBEC0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_647_3461"
          x1="91.1885"
          y1="230.613"
          x2="41.8059"
          y2="269.338"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBBEC0" />
          <stop offset="1" stopColor="#BBBEC0" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_647_3461"
          x1="52.9405"
          y1="114.47"
          x2="24.781"
          y2="191.787"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBBEC0" />
          <stop offset="1" stopColor="#BBBEC0" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
