import { ConfirmContext, IConfirmProvider } from '@app/confirm-provider';
import { useContext } from 'react';

export const useConfirm = (): IConfirmProvider => {
  const context = useContext(ConfirmContext);
  if (!context) {
    throw new Error('useConfirm must be used within an ConfirmContext');
  }
  return context;
};
