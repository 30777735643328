import * as stylex from '@stylexjs/stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
import { anatomy } from '@shared/tokens/anatomy.stylex';
import { colors } from '@shared/tokens/colors.stylex';

export const styles = stylex.create({
  content: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: fonts.din2014Regular,
    fontSize: anatomy['size-16'],
    color: colors.primaryText,
    gap: '10px',
  },
  link: {
    cursor: 'pointer',
  },
});
