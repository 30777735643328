import useFetchQrCode from '@shared/hooks/useFetchQrCodeAdmin';
import { QrCode } from '@shared/ui/qr-code';
import { PreloaderCircular } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { styles } from './styles';

export const QrPageAdmin: FC = () => {
  const { user, event } = useParams();
  const { data, fetching, error } = useFetchQrCode(parseInt(user as string, 10), parseInt(event as string, 10), 261);

  if (fetching)
    return (
      <div {...stylex.props(styles.preloaderContainer)}>
        <PreloaderCircular variant="secondary" />
      </div>
    );
  if (error) return <Navigate to="/error" />;

  return (
    <div {...stylex.props(styles.container)}>
      {data?.getAuthQrcodeByUserId && (
        <>
          <QrCode svg={data.getAuthQrcodeByUserId} />
          <p {...stylex.props(styles.title)}>Ваш QR</p>
          {/* <p {...stylex.props(styles.subTitle)}>Для прохода на мероприятие предъявите его организаторам</p> */}
        </>
      )}
    </div>
  );
};
