import stylex from '@stylexjs/stylex';

import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';
// import { anatomy } from '@shared/tokens/anatomy.stylex';

export const styles = stylex.create({
  base: {
    width: '954px',
    fontFamily: fonts.din2014Regular,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  header: {
    padding: 0,
    color: colors.primaryText,
  },

  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%',
    height: '400px',
    overflowX: 'auto',
    padding: '0 0 20px 20px',
  },

  input: {
    padding: '20px 0 20px 0',
  },

  switcherLabel: {
    fontSize: '14px',
    paddingLeft: '8px',
    color: colors.primaryText,
  },

  dateLabel: {
    fontSize: '14px',
    fonWeight: '400',
    paddingLeft: '16px',
    color: colors.ghostText,
  },
  tabs: {
    width: 'auto',
  },
  qrButtonsPanel: { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '16px' },
  menuItem: {
    color: colors.primaryText,
    fontSize: '14px',
  },
  segment: {
    padding: '10px 0 ',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '20px',
  },
  label: {
    paddingRight: '10px',
  },
});
