import { colors } from '@shared/tokens/colors.stylex';
import stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  bordered: {
    flexGrow: '1',
    alignSelf: 'end',
    marginBottom: '.5px',
    borderBottom: `1px solid ${colors.secondaryBorder}`,
  },
});
