import * as stylex from '@stylexjs/stylex';
import { keyframes } from '@stylexjs/stylex';
import { colors } from '../../shared/tokens/colors.stylex';
import { fonts } from '../../shared/tokens/fonts.stylex';
import { typography } from '../../shared/tokens/typography.stylex';

const skeletonLoading = keyframes({
  '0%': {
    backgroundPosition: '200% 0',
  },
  '100%': {
    backgroundPosition: '-200% 0',
  },
});

export const styles = stylex.create({
  base: {
    width: '302px',
    height: '216px',
    background: colors.primaryBackground,
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
  skeleton: {
    background: `linear-gradient(90deg, ${colors.tertiaryBackground} 25%, ${colors.ghostBackground} 50%, ${colors.tertiaryBackground} 75%)`,
    backgroundSize: '200% 100%',
    animationName: skeletonLoading,
    animationDuration: '1.5s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    borderRadius: '16px',
  },

  title: {
    display: 'flex',
    padding: '16px',
    gap: '12px',
    boxSizing: 'border-box',
    height: '80px',
    width: '302px',
  },
  icon: {
    ':not(#__unused__) > svg': {
      borderRadius: '48px',
      width: '48px',
      height: '48px',
      background: '#1D252D',
      padding: '4px',
      border: `1px solid ${colors.ghostBorder}`,
    },
  },
  titleText: {
    width: '210px',
    textAlign: 'center',
    margin: 'auto',
    fontFamily: fonts.din2014Regular,
    color: colors.primaryText,
    fontSize: typography['fontSizeBody-3'],
    fontWeight: typography.fontWeighDemi,
    lineHeight: typography['fontSizeHeading-4'],
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  infoWidget: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoText: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-2'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['fontSizeCaption-2'],
    color: colors.ghostText,
  },
  infoTitle: {
    color: colors.tertiaryText,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    width: '302px',
    justifyContent: 'space-between',
    padding: '12px 16px 16px 16px',
    boxSizing: 'border-box',
  },
  switcher: {
    display: 'flex',
    alignItems: 'center',
    height: '20px',
  },
  info: {
    width: '100%',
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    paddingBottom: '12px',
  },
  infoValue: {
    width: '138px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  first: (width) => ({
    background: width === 0 ? 'none' : colors.ghostBackground,
    // borderRadius: anatomy.borderRadiusXs,
    borderRadius: '4px',
  }),
  second: (width) => ({
    width: width === 0 ? '10px' : `${width}px`,
    height: '24px',
    // borderRadius: anatomy.borderRadiusXs,
    borderRadius: '4px',
    background: width === 0 ? 'none' : colors.tertiaryBackground,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  third: (width) => ({
    width: width === 0 ? '15px' : `${width}px`,
    height: '24px',
    color: width === 0 ? '' : colors.primaryInverseText,
    background: width === 0 ? 'none' : colors.primaryInverseBackground,
    // borderRadius: anatomy.borderRadiusXs,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  switchLabel: (active) => ({
    color: active ? colors.successText : colors.ghostText,
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-1'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightCaption-1'],
  }),
  date: {
    color: colors.ghostText,
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-1'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightCaption-1'],
  },
  divider: {
    width: '270px',
    border: '1px solid',
    color: colors.ghostBorder,
    marginTop: 'auto',
  },
});
