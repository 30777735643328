import { colors } from '@shared/tokens/colors.stylex';
import { typography } from '@shared/tokens/typography.stylex';
import stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  body: { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: '12px' },
  title: {
    fontFamily: 'DIN-2014_Regular',
    fontSize: typography['fontSizeCaption-1'],
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightCaption-1'],
    color: colors.primaryText,
    maxWidth: '50rem',
    overflow: 'hidden',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
  },
  column: { display: 'flex', flexDirection: 'column', alignItems: 'start' },
});
