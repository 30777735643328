import stylex from '@stylexjs/stylex';
import { styles } from './styles';

import { Checkbox } from '@sminex/sminex-uikit';
import { FC } from 'react';
import { IconButton } from '@shared/ui/icon-button';
import { TrashIcon } from '@shared/ui/icons/trash-icon';
import PlusIcon from '@shared/ui/plus-icon';
import { colors } from '@shared/tokens/colors.stylex';

interface ISegmentProps {
  checked?: boolean;
  isIndeterminate?: boolean;
  title: string;
  onDelete?: () => void;
  onAddParticipants?: () => void;
  onChange: (checked: boolean) => void;
}

export const Segment: FC<ISegmentProps> = ({
  title,
  onDelete,
  onAddParticipants,
  checked,
  isIndeterminate,
  onChange,
}) => {
  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.body)}>
        <Checkbox isIndeterminate={isIndeterminate} isChecked={checked} onChange={() => onChange(!checked)} />
        <p {...stylex.props(styles.title)}>{title}</p>
      </div>
      <div {...stylex.props(styles.actions)}>
        <IconButton icon={<TrashIcon color="#DD524C" />} onClick={onDelete} />
        <IconButton icon={<PlusIcon fillColor={colors.primaryIcon} />} onClick={onAddParticipants} />
      </div>
    </div>
  );
};
