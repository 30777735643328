const EventText = () => {
  return (
    <svg width="49" height="15" viewBox="0 0 49 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.0316 14.6544C45.5766 14.6544 45.1793 14.5905 44.8398 14.4626C44.5002 14.328 44.2252 14.1497 44.0146 13.9276C43.8109 13.6987 43.6445 13.4093 43.5155 13.0594C43.3933 12.7094 43.3084 12.346 43.2608 11.9691C43.2133 11.5855 43.1895 11.1413 43.1895 10.6365V4.40785H41.2744V3.84253H43.1895V0.632275L44.7481 0.551514V3.84253H47.9569L47.8652 4.40785H44.7481V11.2624C44.7481 11.5249 44.7481 11.7302 44.7481 11.8783C44.7549 12.0263 44.7685 12.2181 44.7888 12.4537C44.8092 12.6825 44.8364 12.8642 44.8703 12.9988C44.9111 13.1334 44.9688 13.2848 45.0435 13.4531C45.1182 13.6146 45.2099 13.7391 45.3185 13.8266C45.4272 13.9141 45.563 13.9881 45.726 14.0487C45.8958 14.1093 46.0859 14.1396 46.2965 14.1396C46.9009 14.1396 47.4917 14.0117 48.069 13.7559L48.2727 14.19C47.5121 14.4996 46.7651 14.6544 46.0316 14.6544Z"
        fill="#AE955F"
      />
      <path
        d="M30.9064 14.4931L30.9675 13.2614V4.8522L30.8962 3.87297L32.4548 3.79221L32.2816 5.58914H32.3326C32.7604 5.08439 33.3445 4.63684 34.0847 4.24649C34.8249 3.84942 35.5855 3.65088 36.3665 3.65088C37.2833 3.65088 37.9896 3.92681 38.4853 4.47868C38.9879 5.03055 39.2392 5.83143 39.2392 6.88132V13.2614L39.3105 14.4931H37.6806L37.7519 13.2614V7.41636C37.7519 7.16735 37.7451 6.95535 37.7315 6.78037C37.718 6.59866 37.6874 6.39003 37.6399 6.15447C37.5991 5.91892 37.5278 5.72038 37.4259 5.55886C37.3241 5.39734 37.1916 5.24254 37.0286 5.09448C36.8657 4.93969 36.6517 4.82528 36.3869 4.75125C36.122 4.67722 35.8164 4.6402 35.4701 4.6402C34.8385 4.6402 34.2952 4.76134 33.8402 5.00363C33.3852 5.24591 32.9268 5.59587 32.465 6.05352V13.2614L32.5261 14.4931H30.9064Z"
        fill="#AE955F"
      />
      <path
        d="M24.1602 14.6849C22.5982 14.6849 21.4132 14.2037 20.605 13.2413C19.7969 12.2721 19.3928 10.9295 19.3928 9.2133C19.3928 7.43655 19.8105 6.06698 20.6458 5.10458C21.4879 4.13544 22.656 3.65088 24.15 3.65088C25.4607 3.65088 26.4318 4.0143 27.0634 4.74115C27.7018 5.468 28.021 6.47415 28.021 7.7596C28.021 8.08937 28.004 8.39559 27.97 8.67825L27.9293 8.71864L21.0431 8.72873C21.0499 10.6603 21.3181 12.0366 21.8478 12.8576C22.3775 13.672 23.2196 14.0792 24.3741 14.0792C25.3521 14.0792 26.3334 13.7763 27.3181 13.1706L27.6033 13.6753C26.5507 14.3484 25.403 14.6849 24.1602 14.6849ZM21.0431 8.27445L26.4522 8.18359V7.46684C26.4522 6.33618 26.2553 5.50502 25.8614 4.97334C25.4743 4.43493 24.8767 4.16573 24.0685 4.16573C23.1449 4.16573 22.4353 4.51233 21.9395 5.20553C21.4505 5.89873 21.1517 6.9217 21.0431 8.27445Z"
        fill="#AE955F"
      />
      <path
        d="M13.1958 14.4929L9.53872 4.82176L9.08032 3.84253H10.7509L11.026 4.84195L13.3995 11.6764L13.9088 13.2613H14.0413L17.2704 4.82176L17.4844 3.84253H18.503L18.0141 4.82176L14.2348 14.4929H13.1958Z"
        fill="#AE955F"
      />
      <path
        d="M0.793701 14.4929L0.865008 13.2411V1.84374L0.793701 0.834229H7.87347L7.81235 1.48032L6.45752 1.39956H2.39302V6.97207L7.64936 6.9014V7.47683H2.39302V13.9175H6.47789L7.79198 13.8569V14.4929H0.793701Z"
        fill="#AE955F"
      />
    </svg>
  );
};

export default EventText;
