import { colors } from '@shared/tokens/colors.stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
import { palette } from '@shared/tokens/palette.stylex';
import { typography } from '@shared/tokens/typography.stylex';
import * as stylex from '@stylexjs/stylex';

export const styles = stylex.create({
  container: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette['bronze-100'],
    width: '40px',
    minWidth: '40px',
    height: '40px',
    border: `1px solid ${colors.tertiaryBorder}`,
    borderRadius: '40px',
    margin: '0 8px',
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeCaption-1'],
    fontWeight: typography.fontWeighDemi,
    textTransform: 'uppercase',
  },
});
