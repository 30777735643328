import { AuthContext } from '@app/auth-provider';
import { useContext } from 'react';
import { IAuthContext } from '@app/auth-provider';

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
