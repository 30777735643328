import { ReactNode, useState, createContext } from 'react';
import { getJWTTokens, removeJWTTokens, setJWTokens } from '../shared/libs/local-storage';

interface IAuthContextData {
  email: string | null;
  accessToken: string | null;
  refreshToken: string | null;
}

export interface IAuthContext {
  data: IAuthContextData;
  login: (email: string, accessToken: string, refreshToken: string) => void;
  logout: () => void;
}

interface IProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const AuthProvider = ({ children }: IProviderProps) => {
  const [authState, setAuthState] = useState(getJWTTokens());

  const login = (email: string, accessToken: string = '', refreshToken: string = '') => {
    setAuthState({
      email,
      accessToken,
      refreshToken,
    });
    setJWTokens(email, accessToken, refreshToken);
  };

  const logout = () => {
    setAuthState({
      email: null,
      accessToken: null,
      refreshToken: null,
    });
    removeJWTTokens();
  };

  return <AuthContext.Provider value={{ data: authState, logout, login }}>{children}</AuthContext.Provider>;
};
